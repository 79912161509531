import React ,{useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {Stack} from "@mui/material";
import {sendPasswordReset} from "./firebase";

export default function ResetPasswordModal(props) {
  const [email, setEmail] = useState("");

  const handleClose = (e) => {
      if (props.handleClose) {
          props.handleClose();
      }
  };

  const resetAndClose= (e) => {
      sendPasswordReset(email);
  };

  return (
    <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Reset Password"}</DialogTitle>
      <DialogContent>
          <Stack direction={'column'} spacing={2}>
              <DialogContentText id="alert-dialog-description">
                  To reset your password, add your email below and click "Reset Password"
              </DialogContentText>
              <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
              />
          </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" >
          Cancel
        </Button>
        <Button onClick={resetAndClose} color="primary" variant={'contained'} autoFocus>
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
}