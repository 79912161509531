import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export default function (props) {
    return (
        <div>
            <Stack direction={'column'}>
                <Typography variant={'h6'}>
                    {props.building.title}
                </Typography>
                <Grid container spacing={2}>
                    {
                        props.building && props.building.infos && props.building.infos.map((info, key) => {
                            return (
                                <Grid item>
                                    <Stack direction={'column'}>
                                        <Typography variant={'caption'}>
                                            {info.title}
                                        </Typography>
                                        <Typography variant={'body1'}>
                                            {info.text}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {
                    props.building.sold 
                        ?
                        <Button fullWidth color={'error'}>
                            SOLGT
                        </Button>
                        :
                        <Button fullWidth color={'success'}>
                            TIL SALGS
                        </Button>
                }
            </Stack>
        </div>
    )
}