import React, {useEffect, useState} from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {IconButton, Stack} from "@mui/material";
import {useSnackbar} from "notistack";
import Box from "@mui/material/Box";
import {DataGrid, GridActionsCellItem, GridToolbarContainer} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddBox from '@mui/icons-material/AddBox';
import {useNavigate} from "react-router-dom";
import {createUnit, deleteUnit, getUnits} from "../../interfaces/housingUnit";
import LaunchIcon from '@mui/icons-material/Launch';
import LinkIcon from "@mui/icons-material/Link";

export default function ProjectUnitTable(props) {
    const {projectId} = props;
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [newUnitName, setNewUnitName] = useState("");
    const [units, setUnits] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        init();
    }, [projectId]);

    async function init () {
        if (projectId) {
            const updatedUnits = await getUnits(projectId);
            setUnits(updatedUnits);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleCreateClick () {
        props.BackdropFn(true)

        let newHousingUnit = {
            name: newUnitName,
            description: '',
            numRooms: 0,
            propertyType: 'Apartment',
            ownershipType: 'Selveier',
            lotType: '',
            constructionYear: 0,
            usableSize: 0,
            totalSize: 0,
            unitType: 'metric',
            askingPrice: 0,
            currency: 'NOK',
            coverImage: '',
            documents: [],
            blueprints: [],
            registeredDate: new Date(),
            inspectionDate: null,
            commissionedDate: null,
            marketedDate: null,
            showingDate: null,
            soldDate: null
        }
        
        const newDoc = await createUnit (projectId, newHousingUnit);
        
        setUnits(prevUnits => [...prevUnits, {
            ...newHousingUnit,
            id: newDoc.id
        }])

        enqueueSnackbar("Unit created", {variant: 'success'});
        props.BackdropFn(false)
        // props.refresh();

        handleClose();
    }
    
    async function handleDeleteUnit (projectId, unitId) {
        deleteUnit(projectId, unitId);
    }
    
    function editUnit (projectId, unitId) {
        navigate(`/housing-selector/edit/${projectId}/${unitId}`);
    }
    
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            flex: 1,
            renderCell: (params) => (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {params.value}
                    <IconButton
                        size="small"
                        variant={'outlined'}
                        onClick={event => editUnit(projectId, params.id)}
                        sx={{marginLeft: '10px'}}
                    >
                        <LaunchIcon sx={{height: '15px', width: '15px'}} />
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'askingPrice',
            headerName: 'Asking price',
            width: 150,
            renderCell: (params) => (
                <div>
                    {new Intl.NumberFormat('no-NB', {style: 'currency', currency: 'NOK', maximumFractionDigits: 0,}).format(params.value)}
                </div>
            )
        },
        {
            field: 'propertyType',
            headerName: 'Type',
            width: 150,
        },
        {
            field: 'ownershipType',
            headerName: 'Ownership',
            width: 150,
        },
        {
            field: 'numRooms',
            headerName: 'Rooms',
            width: 80,
        },
        {
            field: 'usableSize',
            headerName: 'Usable Size',
            width: 110,
        },
        {
            field: 'soldDate',
            headerName: 'Sold',
            width: 80,
            type: 'boolean',
            valueGetter: (row) => {
                return row.value != null;
            },
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={e => handleDeleteUnit(projectId, params.id)}
                />,
            ],
        },
    ];

    function EditToolbar(props) {
        const { handleClickOpen } = props;

        const handleClick = () => {
            if (handleClickOpen)
                handleClickOpen();
        };

        return (
            <GridToolbarContainer>
                <Button color="primary" variant={'outlined'} startIcon={<AddBox />} onClick={handleClick}>
                    Ny enhet
                </Button>
            </GridToolbarContainer>
        );
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Lag en ny enhet"}
                </DialogTitle>
                <DialogContent>
                    <Stack direction={'column'} spacing={2} style={{width: '400px', marginTop: '20px'}}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            value={newUnitName}
                            onChange={e => setNewUnitName(e.target.value)}
                            label="Enhetens navn"
                            name="name"
                            autoComplete="name"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateClick}
                            variant={"contained"}
                            disabled={newUnitName.length === 0}
                    >
                        Lag
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={units}
                    columns={columns}
                    // rowHeight={40}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    pageSizeOptions={[5, 10, 50]}
                    checkboxSelection
                    density={'compact'}
                    disableRowSelectionOnClick={true}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    componentsProps={{
                        toolbar: {
                            handleClickOpen,
                        },
                    }}
                />
            </Box>
        </div>
    );
}