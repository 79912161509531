import React, {useEffect, useState} from "react";
import TextField from '@mui/material/TextField';
import {useSnackbar} from "notistack";
import {Link, useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {addImage, getUnit, updateUnit} from "../../interfaces/housingUnit";
import CircularProgress from "@mui/material/CircularProgress";
import {
    ButtonGroup,
    CardHeader,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Tab, Tabs, useTheme
} from "@mui/material";
import Button from "@mui/material/Button";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {FileUploader} from "react-drag-drop-files";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import {NumberFormatBase} from "react-number-format";
import BedIcon from '@mui/icons-material/Bed';
import StairsIcon from '@mui/icons-material/Stairs';
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {DataGrid, GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";

function getLocaleFromCurrency (currency) {
    switch (currency) {
        case 'NOK':
            return 'no-NB';
        case 'USD':
            return 'en-US';
        default:
            return 'no-NB';
    }
}

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        const format = (numStr) => {
            if (numStr === '') return '';
            return new Intl.NumberFormat(getLocaleFromCurrency(props.currency), {
                style: 'currency',
                currency: props.currency,
                maximumFractionDigits: 0,
            }).format(numStr);
        };

        return (
            <NumberFormatBase
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                valueIsNumericString
                format={format}
            />
        );
    },
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {
                value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )
            }
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function HousingUnitEdit(props) {
    const navigate = useNavigate();
    const { projectId, unitId } = useParams();
    const [contentTabIndex, setContentTabIndex] = useState(0);

    const [open, setOpen] = useState(false);
    const [unit, setUnit] = useState(undefined);

    const {enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        init();
    }, [projectId, unitId]);

    async function init () {
        if (projectId && unitId) {
            const updatedUnit = await getUnit(projectId, unitId);
            setUnit(updatedUnit);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleCreateClick () {

    }

    async function deleteUnit (projectId, unitId) {
        console.log("delete unit")
    }

    async function editUnit (projectId, unitId) {
        navigate(`/housing-selector/edit/${projectId}/${unitId}`);
    }

    function updateUnitLocal (field, value, save = false) {
        const updatedUnit = {...unit};
        updatedUnit[field] = value;
        setUnit(updatedUnit)

        if (save) {
            updateUnit(projectId, unitId, updatedUnit);
        }
    }

    async function saveUnitChanges (event) {
        if (event)
            event.preventDefault();

        await updateUnit(projectId, unitId, unit);
    }

    async function handleChangeFilesToUpload (fileList, type) {
        for (const file of fileList) {
            await addImage(projectId, unitId, file, type)
        }
    }
    
    function deleteContent (projectId, id, type) {
        switch (type) {
            case 'blueprints': {
                
            }
            case 'images': {
                
            }
            case 'documents': {
                
            }
        }
    }

    if (!unit) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div>
            <form onSubmit={saveUnitChanges} id="testForm">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                            <Typography variant={'h3'}>
                                {unit.name}
                            </Typography>
                            <Button variant={'contained'} 
                                    component={Link} 
                                    to={`/housing-selector/${projectId}/${unitId}`} 
                                    startIcon={<LinkIcon />}
                            >
                                Besøk enheten
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={'Fakta'}
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Stack direction={'column'} spacing={2}>
                                            <TextField
                                                label={"Name"}
                                                value={unit.name}
                                                onChange={e => updateUnitLocal('name', e.target.value)}
                                                onBlur={saveUnitChanges}
                                                fullWidth
                                            />
                                            <TextField
                                                label={"Description"}
                                                value={unit.description}
                                                multiline
                                                rows={6}
                                                onChange={e => updateUnitLocal('description', e.target.value)}
                                                onBlur={saveUnitChanges}
                                                fullWidth
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction={'column'} spacing={2}>
                                            <Stack direction={'row'} spacing={2}>
                                                <TextField
                                                    label={"Usable Size"}
                                                    type={'number'}
                                                    value={unit.usableSize}
                                                    onChange={e => updateUnitLocal('usableSize', e.target.value)}
                                                    onBlur={saveUnitChanges}
                                                />
                                                <TextField
                                                    label={"Total Size"}
                                                    type={'number'}
                                                    value={unit.totalSize}
                                                    onChange={e => updateUnitLocal('totalSize', e.target.value)}
                                                    onBlur={saveUnitChanges}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="propertyType">Unit Type</InputLabel>
                                                    <Select
                                                        labelId="unitType"
                                                        id="unitType"
                                                        value={unit.unitType}
                                                        label="Unit Type"
                                                        onChange={e => {
                                                            updateUnitLocal('unitType', e.target.value, true);
                                                        }}
                                                        onBlur={saveUnitChanges}
                                                    >
                                                        <MenuItem value={'Metric'}>Metric</MenuItem>
                                                        <MenuItem value={'Imperial'}>Imperial</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>

                                            <Stack direction={'row'} spacing={2}>
                                                <TextField
                                                    label={"Rooms"}
                                                    type={'number'}
                                                    value={unit.numRooms}
                                                    onChange={e => updateUnitLocal('numRooms', e.target.value)}
                                                    onBlur={saveUnitChanges}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <BedIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    label={"Floor"}
                                                    type={'number'}
                                                    value={unit.floor}
                                                    onChange={e => updateUnitLocal('floor', e.target.value)}
                                                    onBlur={saveUnitChanges}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <StairsIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker label={"Construction Year"}
                                                                openTo="year"
                                                                views={['year']}
                                                                onChange={(date) => {
                                                                    const year = new Date(date).getFullYear();
                                                                    updateUnitLocal('constructionYear', year, true);
                                                                }}
                                                                value={new Date(unit.constructionYear, 1)}
                                                                renderInput={(props) => <TextField {...props} />}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>

                                            <Stack direction={'row'} spacing={2}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="propertyType">Property</InputLabel>
                                                    <Select
                                                        labelId="propertyType"
                                                        id="propertyType"
                                                        value={unit.propertyType}
                                                        label="Property"
                                                        onChange={e => {
                                                            updateUnitLocal('propertyType', e.target.value, true);
                                                        }}
                                                        onBlur={saveUnitChanges}
                                                    >
                                                        <MenuItem value={'Apartment'}>Apartment</MenuItem>
                                                        <MenuItem value={'House'}>House</MenuItem>
                                                        <MenuItem value={'Test'}>Test</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <FormControl fullWidth>
                                                    <InputLabel id="ownershipType">Ownership</InputLabel>
                                                    <Select
                                                        labelId="ownershipType"
                                                        id="ownershipType"
                                                        value={unit.ownershipType}
                                                        label="Ownership"
                                                        onChange={e => {
                                                            updateUnitLocal('ownershipType', e.target.value, true);
                                                        }}
                                                        onBlur={saveUnitChanges}
                                                    >
                                                        <MenuItem value={'Selveier'}>Selveier</MenuItem>
                                                        <MenuItem value={'Test2'}>Test2</MenuItem>
                                                        <MenuItem value={'Test3'}>Test3</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {/*<FormControl fullWidth>*/}
                                                {/*    <InputLabel id="lotType">Lot</InputLabel>*/}
                                                {/*    <Select*/}
                                                {/*        labelId="lotType"*/}
                                                {/*        id="lotType"*/}
                                                {/*        value={unit.lotType}*/}
                                                {/*        label="Lot"*/}
                                                {/*        onChange={async (event) => {*/}
                                                {/*            updateUnitLocal('lotType', event.target.value, true);*/}
                                                {/*        }}*/}
                                                {/*        onBlur={saveUnitChanges}*/}
                                                {/*    >*/}
                                                {/*        <MenuItem value={'Selveier'}>Test1</MenuItem>*/}
                                                {/*        <MenuItem value={'Test2'}>Test2</MenuItem>*/}
                                                {/*        <MenuItem value={'Test3'}>Test3</MenuItem>*/}
                                                {/*    </Select>*/}
                                                {/*</FormControl>*/}
                                            </Stack>

                                            <ButtonGroup variant="contained" fullWidth disableElevation>
                                                <TextField
                                                    label="Asking price"
                                                    fullWidth
                                                    value={unit.askingPrice}
                                                    onChange={e => updateUnitLocal('askingPrice', e.target.value)}
                                                    onBlur={saveUnitChanges}
                                                    name="askingPrice"
                                                    id="askingPrice"
                                                    InputProps={{
                                                        inputComponent: NumericFormatCustom,
                                                    }}
                                                    inputProps={{
                                                        currency: unit.currency
                                                    }}
                                                />
                                                <FormControl sx={{width: '150px'}}>
                                                    <InputLabel id="propertyType">Currency</InputLabel>
                                                    <Select
                                                        labelId="currency"
                                                        id="currency"
                                                        value={unit.currency}
                                                        label="Currency"
                                                        onChange={e => {
                                                            updateUnitLocal('currency', e.target.value, true);
                                                        }}
                                                        onBlur={saveUnitChanges}
                                                    >
                                                        <MenuItem value={'NOK'}>NOK</MenuItem>
                                                        <MenuItem value={'USD'}>USD</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ButtonGroup>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Button type={'submit'} variant={'outlined'} color={'primary'}>Save changes</Button>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={8}>
                        <Card>
                            <CardHeader
                                title={'Innhold'}
                            />
                            <CardContent>
                                <Tabs
                                    value={contentTabIndex}
                                    onChange={(event, newValue) => setContentTabIndex(newValue)}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                >
                                    <Tab label="Plantegninger" {...a11yProps(0)} />
                                    <Tab label="Bilder" {...a11yProps(1)} />
                                    <Tab label="Dokumenter" {...a11yProps(2)} />
                                </Tabs>
                                <TabPanel value={contentTabIndex} index={0}>
                                    <FileUploader
                                        multiple={true}
                                        handleChange={fileList => handleChangeFilesToUpload(fileList, "blueprints")}
                                        name="file"
                                        types={["JPEG", "PNG", "GIF", "WEBP", "JPG"]}
                                    />
                                    <div style={{height: 400, width: '100%'}}>
                                        <div style={{display: 'flex', height: '100%'}}>
                                            <div style={{flexGrow: 1}}>
                                                <DataGrid
                                                    rows={unit.blueprints ? unit.blueprints.map(((x, index) => ({
                                                        ...x,
                                                        id: index
                                                    }))) : []}
                                                    columns={[
                                                        {
                                                            field: 'url',
                                                            headerName: 'Preview',
                                                            width: 150,
                                                            renderCell: (params) => (
                                                                <img src={params.value} height={'90%'} />
                                                            )
                                                        },
                                                        {
                                                            field: 'name',
                                                            headerName: 'Name',
                                                            flex: 1,
                                                        },
                                                        {
                                                            field: 'actions',
                                                            type: 'actions',
                                                            width: 80,
                                                            getActions: (params) => [
                                                                <GridActionsCellItem
                                                                    icon={<DeleteIcon />}
                                                                    label="Delete"
                                                                    onClick={e => deleteContent(projectId, params.id, 'blueprints')}
                                                                />,
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={contentTabIndex} index={1}>
                                    <FileUploader
                                        multiple={true}
                                        handleChange={fileList => handleChangeFilesToUpload(fileList, "images")}
                                        name="file"
                                        types={["JPEG", "PNG", "GIF", "WEBP", "JPG"]}
                                    />
                                    <div style={{height: 400, width: '100%'}}>
                                        <div style={{display: 'flex', height: '100%'}}>
                                            <div style={{flexGrow: 1}}>
                                                <DataGrid
                                                    rows={unit.images ? unit.images.map(((x, index) => ({
                                                        ...x,
                                                        id: index
                                                    }))) : []}
                                                    columns={[
                                                        {
                                                            field: 'url',
                                                            headerName: 'Preview',
                                                            width: 150,
                                                            renderCell: (params) => (
                                                                <img src={params.value} height={'90%'}/>
                                                            )
                                                        },
                                                        {
                                                            field: 'name',
                                                            headerName: 'Name',
                                                            flex: 1,
                                                        },
                                                        {
                                                            field: 'actions',
                                                            type: 'actions',
                                                            width: 80,
                                                            getActions: (params) => [
                                                                <GridActionsCellItem
                                                                    icon={<DeleteIcon/>}
                                                                    label="Delete"
                                                                    onClick={e => deleteContent(projectId, params.id, 'images')}
                                                                />,
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={contentTabIndex} index={2}>
                                    <FileUploader
                                        multiple={true}
                                        handleChange={fileList => handleChangeFilesToUpload(fileList, "documents")}
                                        name="file"
                                        types={["PDF", "DOCX", "XLSX"]}
                                    />
                                    <div style={{height: 400, width: '100%'}}>
                                        <div style={{display: 'flex', height: '100%'}}>
                                            <div style={{flexGrow: 1}}>
                                                <DataGrid
                                                    rows={unit.documents ? unit.documents.map(((x, index) => ({
                                                        ...x,
                                                        id: index
                                                    }))) : []}
                                                    columns={[
                                                        {
                                                            field: 'url',
                                                            headerName: 'Preview',
                                                            width: 150,
                                                            renderCell: (params) => (
                                                                <img src={params.value} height={'90%'}/>
                                                            )
                                                        },
                                                        {
                                                            field: 'name',
                                                            headerName: 'Name',
                                                            flex: 1,
                                                        },
                                                        {
                                                            field: 'actions',
                                                            type: 'actions',
                                                            width: 80,
                                                            getActions: (params) => [
                                                                <GridActionsCellItem
                                                                    icon={<DeleteIcon/>}
                                                                    label="Delete"
                                                                    onClick={e => deleteContent(projectId, params.id, 'documents')}
                                                                />,
                                                            ],
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardHeader
                                title={'Datoer'}
                            />
                            <CardContent>
                                <Table size={'dense'}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Opprettet</TableCell>
                                            <TableCell align={'right'}>
                                                {unit.registeredDate.toLocaleDateString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Commisioned</TableCell>
                                            <TableCell align={'right'}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker onChange={(date) => {
                                                        console.log(new Date(date))
                                                        updateUnitLocal('commissionedDate', new Date(date), true);
                                                    }}
                                                                value={unit.commissionedDate ? unit.commissionedDate : null}
                                                                renderInput={(props) => <TextField
                                                                    size={'small'} {...props} />}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Inspisert</TableCell>
                                            <TableCell align={'right'}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker onChange={(date) => {
                                                        updateUnitLocal('inspectionDate', new Date(date), true);
                                                    }}
                                                                value={unit.inspectionDate ? unit.inspectionDate : null}
                                                                renderInput={(props) => <TextField size={'small'} {...props} />}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Markedsført</TableCell>
                                            <TableCell align={'right'}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker onChange={(date) => {
                                                        updateUnitLocal('marketedDate', new Date(date), true);
                                                    }}
                                                                value={unit.marketedDate ? unit.marketedDate : null}
                                                                renderInput={(props) => <TextField size={'small'} {...props} />}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Visning</TableCell>
                                            <TableCell align={'right'}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker onChange={(date) => {
                                                        updateUnitLocal('showingDate', new Date(date), true);
                                                    }}
                                                                value={unit.showingDate ? unit.showingDate : null}
                                                                renderInput={(props) => <TextField size={'small'} {...props} />}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Solgt</TableCell>
                                            <TableCell align={'right'}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker onChange={(date) => {
                                                        updateUnitLocal('soldDate', new Date(date), true);
                                                    }}
                                                                value={unit.soldDate ? unit.soldDate : null}
                                                                renderInput={(props) => <TextField size={'small'} {...props} />}
                                                                slotProps={{
                                                                    field: {
                                                                        clearable: true,
                                                                        onClear: () => {
                                                                            updateUnitLocal('soldDate', null, true);
                                                                        }
                                                                    },
                                                                }}
                                                    />
                                                </LocalizationProvider>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}