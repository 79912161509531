import React, {useEffect, useState} from "react";
import "./UnitList.css";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    ListItemButton, Modal,
    Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import {ArrowDownward, ArrowUpward, Tune} from "@mui/icons-material";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import {ContactForm} from "./ContactForm";

const boxStyle = {
    position: 'absolute',
    right: '0px',
    top: '0px',
    bottom: '0px',
    width: '400px',
    zIndex: 100,
    overflow: 'clip',
}
const tableHeaderStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'right',
    userSelect: 'none',
};
const tableSortingIndicatorStyle = {
    fontSize: '14px',
    marginLeft: '3px',
    opacity: 1,
    transform: 'rotate(0deg)',
    transition: 'all 150ms ease-out',
};
const tableSortingIndicatorReverseStyle = {
    fontSize: '14px',
    marginLeft: '3px',
    opacity: 1,
    transform: 'rotate(180deg)',
    transition: 'all 150ms ease-out',

};
const tableSortingIndicatorHiddenStyle = {
    fontSize: '14px',
    marginLeft: '3px',
    opacity: 0,
    transition: 'all 150ms ease-out',

};
const listStyle = {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    '& ul': {padding: 0},
}
const contactModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

export default function UnitList(props) {
    let {units = [], groupingKey = "group", onSelectedCallback, onHoverCallback} = props;
    
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [sortingOptions, setSortingOptions] = useState({key: "name", asc: true});
    const [minMaxPrice, setMinMaxPrice] = useState([0, 100]);
    const [filterPrice, setFilterPrice] = useState([0, 100]);
    const [minMaxUsableSize, setMinMaxUsableSize] = useState([0, 100]);
    const [filterUsableSize, setFilterUsableSize] = useState([0, 100]);
    const [minMaxRooms, setMinMaxRooms] = useState([0, 5]);
    const [filterNumRooms, setFilterNumRooms] = useState(0); //0 means we accept any number of rooms
    const [filterStatus, setFilterStatus] = useState({available: false, sold: false});

    useEffect(() => {
        if (units && units.length > 0) {
            const minPrice = _.minBy(units, unit => unit.price);
            const maxPrice = _.maxBy(units, unit => unit.price);
            setMinMaxPrice([minPrice.price, maxPrice.price])
            setFilterPrice([minPrice.price, maxPrice.price])

            const minUsableSize = _.minBy(units, unit => unit.usableSize);
            const maxUsableSize = _.maxBy(units, unit => unit.usableSize);
            setMinMaxUsableSize([minUsableSize.usableSize, maxUsableSize.usableSize])
            setFilterUsableSize([minUsableSize.usableSize, maxUsableSize.usableSize])

            const minRooms = _.minBy(units, unit => unit.numRooms);
            const maxRooms = _.maxBy(units, unit => unit.numRooms);
            setMinMaxRooms([minRooms.numRooms, maxRooms.numRooms])
            setFilterNumRooms(minRooms.numRooms)
        }
    }, [units])

    function setSorting(key, asc) {
        setSortingOptions({key: key, asc: asc});
    }

    function onSelectedUnit(event, unit) {
        event.preventDefault();

        if (onSelectedCallback)
            onSelectedCallback(unit);
    }

    function onHoverUnit(event, unit) {
        event.preventDefault();

        if (onHoverCallback)
            onHoverCallback(unit);
    }

    const numRoomsButtons = [];
    for (let i = minMaxRooms[0]; i <= minMaxRooms[1]; i++) {
        numRoomsButtons.push(
            <Grid item key={i}>
                <Button variant={filterNumRooms == i ? 'contained' : 'outlined'} size={'small'} onClick={e => setFilterNumRooms(i)}>
                    {`${i}+`}
                </Button>
            </Grid>
        );
    }
    
    let filteredUnits = units;
    filteredUnits = filteredUnits.filter(x => 
        x.price >= filterPrice[0] && 
        x.price <= filterPrice[1] && 
        x.numRooms >= filterNumRooms && 
        x.usableSize >= filterUsableSize[0] && 
        x.usableSize <= filterUsableSize[1]
    );
    if (filterStatus.available) {
        filteredUnits = filteredUnits.filter(x => !x.sold)
    }
    if (filterStatus.sold) {
        filteredUnits = filteredUnits.filter(x => x.sold)
    }
    const sortedUnits = _.orderBy(filteredUnits, ['name'], [sortingOptions.asc ? 'asc' : 'desc']);
    const groupedUnits = Object.groupBy(sortedUnits, ({group}) => group);

    return (
        <div>
            <Modal
                open={contactModalOpen}
                onClose={e => setContactModalOpen(false)}
                aria-labelledby="Contact Modal"
                aria-describedby="Contact Modal"
            >
                <Box sx={contactModalStyle}>
                    <ContactForm />
                </Box>
            </Modal>
            <Box sx={boxStyle} component={Paper} square elevation={0}>
                <Stack direction={'column'} sx={{height: '100%'}}>
                    <Stack direction={'column'} spacing={2} padding={2}>
                        <Accordion elevation={1} disableGutters>
                            <AccordionSummary
                                expandIcon={<Tune/>}
                                aria-controls="filter-content"
                                id="filter-header"
                            >
                                <Typography>Filter</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction={'column'} spacing={2}>
                                    <div>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Typography variant={'body1'}>
                                                Pris
                                            </Typography>
                                            <Typography variant={'body2'}>
                                                {`${new Intl.NumberFormat('no-NB', {
                                                    style: 'currency',
                                                    currency: 'NOK',
                                                    maximumFractionDigits: 0,
                                                }).format(filterPrice[0])} - ${new Intl.NumberFormat('no-NB', {
                                                    style: 'currency',
                                                    currency: 'NOK',
                                                    maximumFractionDigits: 0,
                                                }).format(filterPrice[1])}`}
                                            </Typography>
                                        </Stack>
                                        <Slider
                                            getAriaLabel={() => 'Pris'}
                                            value={filterPrice}
                                            min={minMaxPrice[0]}
                                            max={minMaxPrice[1]}
                                            step={1000}
                                            onChange={(event, newValue) => setFilterPrice(newValue)}
                                        />
                                    </div>
                                    <div>
                                        <Stack direction={'row'} justifyContent={'space-between'}>
                                            <Typography variant={'body1'}>
                                                {`BRA-i m²`}
                                            </Typography>
                                            <Typography variant={'body2'}>
                                                {`${filterUsableSize[0]}m² - ${filterUsableSize[1]}m²`}
                                            </Typography>
                                        </Stack>
                                        <Slider
                                            getAriaLabel={() => 'Pris'}
                                            value={filterUsableSize}
                                            min={minMaxUsableSize[0]}
                                            max={minMaxUsableSize[1]}
                                            step={1}
                                            onChange={(event, newValue) => setFilterUsableSize(newValue)}
                                        />
                                    </div>
                                    <div>
                                        <Typography variant={'body1'}>
                                            Antall rom
                                        </Typography>
                                        <Grid container direction={'row'} spacing={2} padding={1}>
                                            {
                                                numRoomsButtons.map((numRoomsButton, i) => {
                                                    return numRoomsButton;
                                                })
                                            }
                                        </Grid>
                                    </div>
                                    <div>
                                        <Typography variant={'body1'}>
                                            Status
                                        </Typography>
                                        <Stack direction={'column'} justifyContent={'left'}>
                                            <FormControlLabel
                                                value="available"
                                                control={<Checkbox checked={filterStatus.available}
                                                                   onChange={e => {
                                                                       setFilterStatus(oldValue => {
                                                                           return {
                                                                               sold: oldValue.sold,
                                                                               available: e.target.checked
                                                                           }
                                                                       });
                                                                   }} />}
                                                label={"Ledig"}
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="sold"
                                                control={<Checkbox checked={filterStatus.sold}
                                                                   onChange={e => {
                                                                       setFilterStatus(oldValue => {
                                                                           return {
                                                                               sold: e.target.checked,
                                                                               available: oldValue.available
                                                                           }
                                                                       });
                                                                   }} />}
                                                label={"Solgt"}
                                                labelPlacement="end"
                                            />
                                        </Stack>
                                    </div>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Typography variant={'h6'}>
                            {`${filteredUnits.filter(x => !x.sold).length} Enheter til salgs`}
                        </Typography>
                    </Stack>
                    <List
                        sx={listStyle}
                        subheader={<li/>}
                    >
                        <ListItem sx={{paddingTop: '0px', paddingBottom: '0px'}}>
                            <Stack direction={'row'}
                                   sx={{width: '100%', paddingLeft: '8px', paddingRight: '15px'}}>
                                <Typography sx={{...tableHeaderStyle, justifyContent: 'left', width: '25%'}}
                                            align={'left'}
                                            onClick={e => setSorting("name", !sortingOptions.asc)}
                                >
                                    Navn
                                    {
                                        sortingOptions && sortingOptions.key === "name" ?
                                            <ArrowUpward
                                                sx={sortingOptions.asc ? tableSortingIndicatorStyle : tableSortingIndicatorReverseStyle}/>
                                            :
                                            <ArrowUpward sx={tableSortingIndicatorHiddenStyle}/>
                                    }
                                </Typography>
                                <Typography sx={{...tableHeaderStyle, width: '35%'}}
                                            align={'right'}
                                            onClick={e => setSorting("price", !sortingOptions.asc)}
                                >
                                    Pris
                                    {
                                        sortingOptions && sortingOptions.key === "price" ?
                                            <ArrowUpward
                                                sx={sortingOptions.asc ? tableSortingIndicatorStyle : tableSortingIndicatorReverseStyle}/>
                                            :
                                            <ArrowUpward sx={tableSortingIndicatorHiddenStyle}/>
                                    }
                                </Typography>
                                <Typography sx={{...tableHeaderStyle, width: '20%'}}
                                            align={'right'}
                                            onClick={e => setSorting("usableSize", !sortingOptions.asc)}
                                >
                                    BRA-i
                                    {
                                        sortingOptions && sortingOptions.key === "usableSize" ?
                                            <ArrowUpward
                                                sx={sortingOptions.asc ? tableSortingIndicatorStyle : tableSortingIndicatorReverseStyle}/>
                                            :
                                            <ArrowUpward sx={tableSortingIndicatorHiddenStyle}/>
                                    }
                                </Typography>
                                <Typography sx={{...tableHeaderStyle, width: '20%'}}
                                            align={'right'}
                                            onClick={e => setSorting("numRooms", !sortingOptions.asc)}
                                >
                                    Rom
                                    {
                                        sortingOptions && sortingOptions.key === "numRooms" ?
                                            <ArrowUpward
                                                sx={sortingOptions.asc ? tableSortingIndicatorStyle : tableSortingIndicatorReverseStyle}/>
                                            :
                                            <ArrowUpward sx={tableSortingIndicatorHiddenStyle}/>
                                    }
                                </Typography>
                            </Stack>
                        </ListItem>
                        {
                            groupedUnits && Object.keys(groupedUnits).map((groupKey, groupIndex) => (
                                <li key={`${groupKey}`}>
                                    <ul>
                                        <ListSubheader sx={{paddingLeft: '25px', paddingRight: '15px'}}>
                                            {`${groupKey}`}
                                            <Divider/>
                                        </ListSubheader>
                                        {
                                            groupedUnits[groupKey].map((unit) => (
                                                <ListItem key={unit.id} sx={{paddingTop: '0px', paddingBottom: '0px'}}>
                                                    <ListItemButton disableGutters
                                                                    onClick={e => onSelectedUnit(e, unit)}
                                                                    onMouseEnter={e => onHoverUnit(e, unit)}
                                                                    onMouseLeave={e => onHoverUnit(e, null)}
                                                    >
                                                        <Stack direction={'row'}
                                                               sx={{width: '100%', paddingLeft: '8px', paddingRight: '15px'}}>
                                                            <div style={{width: '25%', display: 'flex'}}>
                                                                <div style={{
                                                                    width: '5px',
                                                                    backgroundColor: unit.sold ? 'red' : 'green',
                                                                    height: '100%',
                                                                    marginRight: '5px'
                                                                }}/>
                                                                <Typography sx={{fontSize: '14px'}} align={'left'}>
                                                                    {unit.name}
                                                                </Typography>
                                                            </div>
                                                            <Typography sx={{fontSize: '14px', width: '35%'}} align={'right'}>
                                                                {new Intl.NumberFormat('no-NB', {
                                                                    style: 'currency',
                                                                    currency: 'NOK',
                                                                    maximumFractionDigits: 0,
                                                                }).format(unit.price)}
                                                            </Typography>
                                                            <Typography sx={{fontSize: '14px', width: '20%'}} align={'right'}>
                                                                {`${unit.usableSize}m²`}
                                                            </Typography>
                                                            <Typography sx={{fontSize: '14px', width: '20%'}} align={'right'}>
                                                                {unit.numRooms}
                                                            </Typography>
                                                        </Stack>
                                                    </ListItemButton>
                                                </ListItem>
                                            ))
                                        }
                                    </ul>
                                </li>
                            ))
                        }
                    </List>
                    <Box sx={{boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', padding: '10px'}}>
                        <Button variant={'contained'} fullWidth onClick={e => setContactModalOpen(true)}>
                            Meld interesse
                        </Button>
                    </Box>
                </Stack>
            </Box>   
        </div>
    )
}