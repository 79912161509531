import React from "react"
import {Layer, Line, Circle} from "react-konva"

export default ({ building, isEditing, onCircleDragEnd, onClick }) => {
    let points = [];
    building.points.map(point => {
        points.push(point.x);
        points.push(point.y);
    });

    return (
        <Layer
            key={building.id}
            onClick={evt => onClick(building)}
        >
            <Line
                x={0}
                y={0}
                points={points}
                tension={0}
                closed
                stroke={isEditing ? "rgba(51, 108, 241, 0.5)" : "rgba(255,32,32,0.3)"}
                fill={isEditing ? "rgba(51, 108, 241, 0.5)" : "rgba(255,32,32,0.3)"}
            />
            {
                building.points.map((point, pointIdx) => {
                    return <Circle
                        key={pointIdx}
                        draggable={isEditing}
                        onDragMove={(e) => onCircleDragEnd(e, building, pointIdx)}
                        x={point.x}
                        y={point.y}
                        radius={5} 
                        fill={isEditing ? "rgba(50, 50, 50, 1)" : "rgba(143, 114, 251, 1.0)"} 
                    />
                })
            }
        </Layer>
    )
}