import React, {forwardRef} from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {IconButton, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import HomeIcon from '@mui/icons-material/Home';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router-dom";

const cardStyle = {
}

const cardContentStyle = {
    padding: '10px',
    paddingBottom: '10px !important',
}
const soldTextStyle = {
    backgroundColor: 'red',
    color: 'white',
    width: 'fit-content',
    borderRadius: '5px',
    padding: '1px 6px',
    
}
const availableTextStyle = {
    backgroundColor: 'green',
    color: 'white',
    width: 'fit-content',
    borderRadius: '5px',
    padding: '1px 6px',
}
const textAndIconStyle = {
    display: 'flex',
    alignItems: 'center',
}
const iconStyle = {
    marginRight: '10px',
}
const keyImageContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
}
const keyImageStyle = {
    height: '130px',
}
const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
}

export const UnitCard =  forwardRef((props, ref) => {
    const {unit, comparisonVersion, handleCloseCallback} = props;
    const navigate = useNavigate();
    
    function handleCloseCard (event) {
        if (handleCloseCallback)
            handleCloseCallback();
    }
    
    function handleViewDetails (event) {
        navigate("/housing-selector/KQ0BAd8eZIhSIiBvcQcg/IM3JgWMNwX6TA5sIpbdl")
    }
    
    if (!unit)
        return <></>

    return (
        <Card ref={ref} id={unit.id} sx={cardStyle}>
            {
                !comparisonVersion &&
                <IconButton sx={closeButtonStyle} onClick={handleCloseCard}>
                    <CloseIcon />
                </IconButton>
            }
            <CardContent sx={cardContentStyle}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Stack direction={'column'} spacing={1}>
                            <Typography variant={'h6'}>
                                {unit.name}
                            </Typography>
                            <Typography variant={'caption'} sx={unit.sold ? soldTextStyle : availableTextStyle}>
                                {unit.sold ? "Solgt" : "Ledig"}
                            </Typography>
                            <Typography variant={'body2'} sx={textAndIconStyle}>
                                <LocalOfferIcon sx={iconStyle} />
                                {new Intl.NumberFormat('no-NB', {style: 'currency', currency: 'NOK', maximumFractionDigits: 0,}).format(unit.price)}
                            </Typography>
                            <Typography variant={'body2'} sx={textAndIconStyle}>
                                <MeetingRoomIcon sx={iconStyle} />
                                {`${unit.numRooms} rom`}
                            </Typography>
                            <Typography variant={'body2'} sx={textAndIconStyle}>
                                <HomeIcon sx={iconStyle} />
                                {`${unit.usableSize}m²`}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={7}>
                        <div style={keyImageContainerStyle}>
                            <img src={unit.keyImage} style={keyImageStyle} />
                        </div>
                    </Grid>
                    {
                    !comparisonVersion &&
                        <Grid item xs={4}>
                            <Button variant={'outlined'} fullWidth>
                                Bygg A
                            </Button>
                        </Grid>
                    }
                    {
                        !comparisonVersion &&
                        <Grid item xs={4}>
                            <Button variant={'outlined'} fullWidth>
                                Balkong
                            </Button>
                        </Grid>
                    }
                    {
                        !comparisonVersion &&
                        <Grid item xs={4}>
                            <Button variant={'outlined'} fullWidth>
                                Fasade
                            </Button>
                        </Grid>
                    }
                    {
                        !comparisonVersion &&
                        <Grid item xs={12}>
                            <Button variant={'contained'} fullWidth onClick={handleViewDetails}>
                                Detaljer
                            </Button>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
})