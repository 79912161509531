import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {Link, useParams} from "react-router-dom";
import {addImage, getProject, updateProject} from "../../interfaces/housingProject";
import Grid from "@mui/material/Grid";
import {FileUploader} from "react-drag-drop-files";
import Box from "@mui/material/Box";
import {CardHeader, Stack} from "@mui/material";
import ProjectUnitTable from "../../components/admin/ProjectUnitTable";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import {updateUnit} from "../../interfaces/housingUnit";
import LinkIcon from "@mui/icons-material/Link";

const uploadBoxStyle = {
    width: '100%',
    height: '200px',
    border: 'dotted 2px rgba(255, 255, 255, 0.2)',
    color: 'rgba(255, 255, 255, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 200ms ease-out',
    cursor: 'pointer',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ':hover': {
        border: 'dotted 2px rgba(255, 255, 255, 1)',
        color: 'rgba(255, 255, 255, 1)',
    }
}

export default function HousingProjectEdit(props) {
    const [project, setProject] = useState({});
    const { projectId } = useParams();

    async function createHousingGroup () {
        // const docRef = collection(db, "housingSelector", id, "groups");
    }

    useEffect(() => {
        init();
    }, [projectId]);

    async function init () {
        if (projectId) {
            const updatedProject = await getProject(projectId);
            setProject(updatedProject);
        }
    }

    async function handleKeyImageUpload (file, type) {
        await addImage(projectId, file, type, `keyart.${file.name.split('.').pop()}`)
    }

    function updateProjectLocal (field, value, save = false) {
        const updatedProject = {...project};
        updatedProject[field] = value;
        setProject(updatedProject)

        if (save) {
            updateProject(projectId, updatedProject);
        }
    }

    async function saveProjectChanges (event) {
        if (event)
            event.preventDefault();

        await updateProject(projectId, project);
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} >
                        <Typography variant={'h3'}>
                            {project.projectName}
                        </Typography>
                        {/*<Button variant={'outlined'} component={Link} to={`/housing-selector/${projectId}?target=nordbohusbyen`} >*/}
                        <Button variant={'contained'}
                                component={Link} 
                                to={`/housing-selector/3iUfCJSzDaWjUS5XFBhY?target=nordbohusbyen`} 
                                startIcon={<LinkIcon />}
                        >
                            Besøk prosjekt
                        </Button>
                    </Stack>
                </Grid>
                
                <Grid item xs={12}>
                    <FileUploader
                        multiple={false}
                        handleChange={file => handleKeyImageUpload(file, "keyImage")}
                        name="file"
                        types={["JPEG", "PNG", "GIF", "WEBP", "JPG"]}
                        children={
                            <Box sx={uploadBoxStyle} style={{backgroundImage: `url(${project.keyImage?.url}`}}>
                                <Typography variant={'h6'}>
                                    {`Last opp hovedbilde for ${project.projectName}`}
                                </Typography>
                            </Box>
                        }
                    />
                </Grid>

                <Grid item xs={6}>
                    <Card>
                        <CardHeader title={'Detaljer'} />
                        <CardContent>
                            <TextField 
                                label={'Beskrivelse'}
                                placeholder={"Legg til en beskrivelse..."}
                                value={project.description || ''}
                                onChange={e => updateProjectLocal('description', e.target.value)}
                                onBlur={saveProjectChanges}
                                multiline
                                rows={6}
                                fullWidth
                                variant={'outlined'}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={6}>
                    <Card>
                        <CardHeader title={'Oversiktsdata'} />
                        <CardContent>
                            <Button onClick={createHousingGroup} variant={'outlined'}>
                                Upload new PanoData zip
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={'Enheter'} />
                        <CardContent>
                            <ProjectUnitTable BackdropFn={props.BackdropFn} projectId={projectId} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}