import React from "react";
import './Pano.css';

export default function PanoMarker (id, textbox, onClickCallback = null) {
    return <div key={id}
                id={id}
                className={`marker-hitbox`}
                onClick={(event) => onClickCallback ? onClickCallback(event, id) : null}
    >
       
        <p className={'marker-name'}>{textbox}</p>
        
        <div className={'marker'}>

            <div style={{position:'relative', color:'white'}}>
             +
            </div>

        </div>
        
    </div>
}