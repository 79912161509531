import React, {useEffect, useState} from "react";
import HousingSelectorTest from '../../images/housing-selector-test.png';
import styled from "styled-components"
import Building from "../../components/admin/Building"
import {Stage, Layer, Image} from "react-konva"
import {Save} from "@mui/icons-material";
import { v4 as uuidv4 } from 'uuid';
import {Stack, Tooltip} from "@mui/material";
import InfoComponent from "../../components/admin/InfoComponent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {DataGrid} from "@mui/x-data-grid";
import {getProject, updateProject} from "../../interfaces/housingProject";
import HousingTooltip from "../../components/admin/HousingTooltip";
import {getUnits} from "../../interfaces/housingUnit";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../../../firebase";

let buildingId = 3;

const Container = styled('div')`
  .canvas {
    width: 400px;
    height: 400px;
    background: green;
  }
`

export default function HousingSelector(props) {
    const [projects, setProjects] = useState([]);
    const [img, setImage] = useState(undefined);
    const [creating, setCreating] = useState(false);
    const [activeBuilding, setActiveBuilding] = useState(undefined);
    const [node, setNode] = useState(undefined);
    const [imageData, setImageData] = useState({});

    useEffect(() => {
        init();
    }, [props.userData]);

    async function init () {
        if (props.userData) {
            const project = await getProject('test');

            const img = new window.Image()
            const defaultImg = 'https://firebasestorage.googleapis.com/v0/b/fortrinn-47822.appspot.com/o/housingSelector%2Fhousing-selector-test.png?alt=media&token=9270ab16-da89-4455-9778-63ddf090dd4e';

            const docRef = doc(db, "housingSelector", "test");
            getDoc(docRef)
                .then((doc) => {
                    if (!doc.exists()) {
                        setImageData([]);
                        setDoc(docRef, {
                            img: defaultImg,
                            buildings: [],
                        })

                        img.src = defaultImg;
                        img.addEventListener('load', () => {
                            setImage(img);
                        })
                    } else {
                        const data = doc.data();
                        setImageData(data);

                        img.src = data.img;
                        img.addEventListener('load', () => {
                            setImage(img);
                        })
                    }
                });
        }
    }

    function createBuilding () {
        const newBuilding = {
            id: uuidv4(),
            points: [],
        };

        const updatedImageData = imageData;
        updatedImageData.buildings = [...updatedImageData.buildings, newBuilding];
        setImageData(updatedImageData);

        setImageData(updatedImageData);
        setCreating(true);
    }

    async function saveBuilding () {
        setCreating(false);
        let updatedBuildings = imageData.buildings.filter(({ points }) => points.length > 0).map((building) => {
            if (building.id !== activeBuilding) return building

            const createdBuilding = { ...building, id: buildingId }
            buildingId++
            return createdBuilding
        });

        const updatedImageData = imageData;
        updatedImageData.buildings = updatedBuildings;
        setImageData({...updatedImageData});
        
        await updateProject("test", {
            buildings: updatedBuildings,
        })

        setActiveBuilding(undefined);
    }

    function editBuilding (building) {
        setActiveBuilding(building);
    }

    function onCircleDragEnd (e, building, pointIdx) {
        let updatedBuildings = imageData.buildings.map(b => {
            if (b.id !== building.id) return b

            const updatedPoints = building.points;
            updatedPoints[pointIdx] = {
                x: e.target.x(),
                y: e.target.y(),
            }

            return {
                ...building,
                points: updatedPoints,
            }
        });

        const updatedImageData = imageData;
        updatedImageData.buildings = updatedBuildings;
        setImageData({...updatedImageData});
    }

    function onStageClick ({ evt, target }) {
        if (activeBuilding) {
            return insertNode(evt);
        }
        if (!creating) return;

        let updatedBuildings = imageData.buildings.map((building, idx) => {
            if (idx !== imageData.buildings.length - 1) {
                return building
            }

            let updatedPoints = building.points;
            updatedPoints.push({
                x: evt.offsetX,
                y: evt.offsetY,
            });

            return {
                ...building,
                points: updatedPoints,
            }
        });

        const updatedImageData = imageData;
        updatedImageData.buildings = updatedBuildings;
        setImageData({...updatedImageData});
    }

    function insertNode (target) {
        let updatedBuildings = imageData.buildings.map(building => {
            if (building.id !== activeBuilding) return building

            let updatedPoints = building.points;
            updatedPoints.push({
                x: target.offsetX,
                y: target.offsetY,
            });

            return {
                ...building,
                points: updatedPoints,
            }
        });

        const updatedImageData = imageData;
        updatedImageData.buildings = updatedBuildings;
        setImageData({...updatedImageData});
    }

    function onClickBuilding (building) {
        if (activeBuilding) {
            saveBuilding()
            editBuilding(building.id)
        } else {
            editBuilding(building.id)
        }
    }

    const buildingTestData = {
        title: 'UE2022',
        sold: false,
        infos: [
            {
                title: 'Enhetstype',
                text: 'Leilighet',
            },
            {
                title: 'BRA',
                text: '74 m2',
            },
            {
                title: 'Etasje',
                text: '3',
            },
            {
                title: 'P-ROM',
                text: '60 m2',
            },
            {
                title: 'Rom',
                text: '2',
            },
            {
                title: 'Park',
                text: 'Inkl',
            },
            {
                title: 'Pris',
                text: '2 809 100',
            },
        ]
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'firstName', headerName: 'First name', width: 130 },
        { field: 'lastName', headerName: 'Last name', width: 130 },
        {
            field: 'age',
            headerName: 'Age',
            type: 'number',
            width: 90,
        },
    ];

    const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];
    
    if (!img) return <div>LOADING</div>

    return (
        <div>
            <div>
                <Stack direction={'row'}>
                    <div>
                        <img src={HousingSelectorTest} />
                    </div>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item><InfoComponent icon={<Save />} title={"Enhetstype"} text={"Leilighet"} /></Grid>
                            <Grid item><InfoComponent icon={<Save />} title={"BRA"} text={"74 m2"} /></Grid>
                            <Grid item><InfoComponent icon={<Save />} title={"Etasje"} text={"3"} /></Grid>
                            <Grid item><InfoComponent icon={<Save />} title={"P-ROM"} text={"60 m2"} /></Grid>
                            <Grid item><InfoComponent icon={<Save />} title={"Rom"} text={"2"} /></Grid>
                            <Grid item><InfoComponent icon={<Save />} title={"Park"} text={"Inkl"} /></Grid>
                            <Grid item><InfoComponent icon={<Save />} title={"Pris"} text={"2 809 100"} /></Grid>
                        </Grid>
                    </div>
                </Stack>
                <Tooltip title={
                    <React.Fragment>
                        <HousingTooltip building={buildingTestData} />
                    </React.Fragment>
                }
                         placement={'bottom'}
                         arrow
                >
                    <Button>
                        Tooltip test
                    </Button>
                </Tooltip>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                    />
                </div>
            </div>
            <Container>
                <div>
                    {
                        imageData.buildings.map((building, index) => {
                            if (!building.id) return null
                            return <button
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => editBuilding(building.id)}
                                key={building.id}>Edit Building #{index}</button>
                        })
                    }

                </div>

                {(!creating && !activeBuilding) && <button onClick={createBuilding}>Create</button>}

                {(creating || activeBuilding) && <button onClick={saveBuilding}>Save building</button>}

                <div className="canvas">
                    <Stage
                        onClick={onStageClick}
                        width={img.width} height={img.height}
                    >
                        <Layer>
                            <Image
                                x={0}
                                y={0}
                                width={img.width}
                                height={img.height}
                                image={img}
                            />
                        </Layer>
                        {
                            imageData.buildings.map((building) => {
                                return (
                                    <Building
                                        key={building.id}
                                        isEditing={activeBuilding === building.id}
                                        building={building}
                                        onCircleDragEnd={onCircleDragEnd}
                                        onClick={onClickBuilding}
                                    />
                                );
                            })
                        }

                    </Stage>
                </div>
            </Container>
        </div>
    );
}