import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  sendEmailVerification,
  updateEmail,
  updatePassword,
  linkWithCredential,
  linkWithPopup,
  unlink,
} from "firebase/auth";
import {
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";
import {
  getAnalytics
} from "firebase/analytics";
import {
  getStorage,
} from 'firebase/storage';
import {
  getFunctions,
} from 'firebase/functions';
import * as firestorebase from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDzVxg_e3Ivo0MahCCz-PxtdSjKp8CwXWU",
  authDomain: "fortrinn-47822.firebaseapp.com",
  databaseURL: "https://fortrinn-47822.firebaseio.com",
  projectId: "fortrinn-47822",
  storageBucket: "fortrinn-47822.appspot.com",
  messagingSenderId: "319266294232",
  appId: "1:319266294232:web:f0a61c978418ff2d8b4aad",
  measurementId: "G-XS6GGRNB2R"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = initializeFirestore(app, {ignoreUndefinedProperties: true})
const storage = getStorage(app);
const functions = getFunctions(app,'europe-west1');
//const functions = app.functions('europe-west1');
const firestore = firestorebase;

const googleProvider = new GoogleAuthProvider();
const oAuthProvider = new OAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    // const docRef = doc(db, "users", user?.uid);
    // const docSnap = await getDoc(docRef);

    // if (!docSnap.exists()) {
    //   await setDoc(doc(db, "users", user.uid), {
    //     customProjectsAllowed: 0,
    //     role: 'user',
    //     email: user.email,
    //     name: user.displayName,
    //     domain: 'google.com',
    //   });
    // }

    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const signInWithMicrosoft = async () => {
  try {
    const res = await signInWithPopup(auth, oAuthProvider);
    const user = res.user;

    // const docRef = doc(db, "users", user?.uid);
    // const docSnap = await getDoc(docRef);

    // if (!docSnap.exists()) {
    //   await setDoc(doc(db, "users", user.uid), {
    //     customProjectsAllowed: 0,
    //     role: 'user',
    //     email: user.email,
    //     name: user.displayName,
    //     domain: 'microsoft.com',
    //   });
    // }

    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const linkWithMicrosoft = async () => {
  try {
    return await linkWithPopup(auth.currentUser, oAuthProvider);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const linkWithGoogle = async () => {
  try {
    return await linkWithPopup(auth.currentUser, googleProvider);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const domain = email.split("@").pop();

    // await setDoc(doc(db, "users", user.uid), {
    //   customProjectsAllowed: 0,
    //   role: 'user',
    //   email,
    //   name,
    //   domain: domain,
    // });

    await sendEmailVerification(auth.currentUser)

    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const sendPasswordReset = async (email) => {
  try {
    return await sendPasswordResetEmail(auth, email);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const logout = () => {
  signOut(auth);
};

export {
  app,
  analytics,
  auth,
  db,
  storage,
  functions,
  firestore,
  signInWithGoogle,
  signInWithMicrosoft,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  updatePassword,
  updateEmail,
  linkWithCredential,
  linkWithGoogle,
  linkWithMicrosoft,
  unlink,
  logout,
};