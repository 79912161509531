import React, {useEffect, useState} from "react";
import styled from "styled-components"
import Grid from "@mui/material/Unstable_Grid2";
import {CardHeader, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import HousingProjectAdminCreate from "./HousingProjectAdminCreate";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Edit} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import {getProjects} from '../../interfaces/housingProject';
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import MissingPromoImage from "../../images/missing-promo-image.png";

const Container = styled('div')`
  .canvas {
    width: 400px;
    height: 400px;
    background: green;
  }
`

export default function HousingAdminDashboard(props) {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        init();
    }, [props.userData]);
    
    async function init () {
        if (props.userData) {
            const housingProjects = await getProjects();
            console.log(housingProjects)
            setProjects(housingProjects);
        }
    }

    return (
        <div>
            <Container>
                <Grid container cols={1} justify="center" spacing={3} >
                    <Grid item xs={12}>
                        <Stack direction={"row"} justifyContent={'space-between'}>
                            <Typography variant={'h4'} >
                                Boligprosjekter
                            </Typography>
                            <Stack direction={'row'} spacing={2}>
                                <HousingProjectAdminCreate db={props.db} userData={props.userData} refresh={getProjects} SnackUpdateFn={props.SnackUpdateFn} BackdropFn={props.BackdropFn} />
                            </Stack>
                        </Stack>
                    </Grid>
                    {
                        projects && projects.map((project, index) => (
                            <Grid item xs={12} md={6} lg={4} key={project.id}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={project.keyImage?.url ? "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/0WcS1f3s0N8Jgdz93fwV/projectimage.jpg" : MissingPromoImage}
                                        alt={project.projectName}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {project.projectName}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {project.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Button component={Link} 
                                                to={`/housing-selector/3iUfCJSzDaWjUS5XFBhY?target=nordbohusbyen`} 
                                                // to={`/housing-selector/${project.id}?target=nordbohusbyen`} 
                                                startIcon={<LinkIcon />}
                                                variant={'contained'}
                                        >
                                            Visit
                                        </Button>
                                        <Button component={Link} 
                                                to={`/housing-selector/edit/${project.id}`} 
                                                startIcon={<Edit />}
                                                variant={'outlined'}
                                        >
                                            Edit
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </div>
    );
}