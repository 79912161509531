import React, { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {Link, useNavigate} from "react-router-dom";
import ResetPasswordModal from './ResetPasswordModal';
import Image from './img/archviz.JPG';
import {useAuthState} from "react-firebase-hooks/auth";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
  signInWithMicrosoft
} from "./firebase";
import Copyright from "./Copyright";
import {Card} from "@mui/material/";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {Stack} from "@mui/material";
import {ReactComponent as GoogleIcon} from './img/google-icon.svg';
import {ReactComponent as MsIcon} from './img/ms-icon.svg';
import {validateEmail} from "./validateEmail";
import {useSnackbar} from "notistack";

const SignIn = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleForm = (e) => {
    if(!validateEmail(email)) {
      setEmailError('Not valid email')
    }
    if(password.length == 0) {
      setPasswordError('Password is too short')
    }

    if(passwordError.length > 0 || emailError.length > 0) {
      return;
    }
    
    // if (auth.currentUser.emailVerified == false)

    e.preventDefault();
    logInWithEmailAndPassword(email, password)
        .then(e => {
          enqueueSnackbar('Successfully logged in', {variant: 'success'});
          console.log(auth.currentUser.emailVerified)
        })
        .catch(error => {
          if (error.code == "auth/user-not-found") {
            enqueueSnackbar("User not found", {variant: 'error'});
            setEmailError("User not found")
          }
          if (error.code == "auth/wrong-password") {
            enqueueSnackbar("Incorrect password", {variant: 'error'});
            setPasswordError("Incorrect password")
          }
          if (error.code == "auth/too-many-requests") {
            enqueueSnackbar("Too many failed attempt, user is temporarily locked", {variant: 'error'});
          }
        })
  };

  const handleGoogleLogin = (e) => {
    e.preventDefault();
    signInWithGoogle();
  }

  const handleMicrosoftLogin = (e) => {
    e.preventDefault();
    signInWithMicrosoft();
  }

  useEffect(() => {
    if (loading) return;
    if (user) {
      navigate("/");
    }
  }, [user, loading]);

  return (
      <div style={{height: '100vh'}}>
        <ResetPasswordModal open={resetPasswordModalOpen} handleClose={e => setResetPasswordModalOpen(false)} />
        <Grid container justifyContent={'center'} alignItems={'center'} sx={{height: '100%'}} >
          <Grid item xs={12} sm={8} md={5}>
            <Card square elevation={6}>
              <CardMedia 
                  image={Image} 
                  style={{height: '200px'}}
              />
              <CardContent>
                <form onSubmit={e => handleForm(e)}>
                <Stack direction={'column'} spacing={2} alignItems={'center'}>
                  <Avatar>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography variant="h5">
                    Logg inn
                  </Typography>
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={e => {
                        setEmail(e.target.value);
                        setEmailError('');
                      }}
                      autoFocus
                      error={emailError.length > 0}
                      helperText={emailError.length > 0 ? emailError : ''}
                  />
                  <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      onChange={e => {
                        setPassword(e.target.value);
                        setPasswordError('');
                      }}
                      error={passwordError.length > 0}
                      helperText={passwordError.length > 0 ? passwordError : ''}
                  />
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleForm}
                  >
                    Sign In
                  </Button>
                  <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleMicrosoftLogin}
                      startIcon={<MsIcon style={{height: '24px', width: '24px'}} />}
                  >
                    Sign in with Microsoft
                  </Button>
                  <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleGoogleLogin}
                      startIcon={<GoogleIcon style={{height: '24px', width: '24px'}} />}
                  >
                    Sign in with Google
                  </Button>
                  <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                    <Button component={Link} to={'/signup'}>
                      Sign up instead?
                    </Button>
                    <Button onClick={e => setResetPasswordModalOpen(true)} >
                      Reset your password?
                    </Button>
                  </Stack>
                  <Copyright />
                </Stack>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
  );
}

export default SignIn;