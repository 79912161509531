import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {Stack} from "@mui/material";
import {useSnackbar} from "notistack";
import {createProject} from "../../interfaces/housingProject";
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';

export default function HousingProjectAdminCreate(props) {
    const [open, setOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState("");
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateClick = async () => {
        props.BackdropFn(true)

        let newProject = {
            projectName: projectName,
            buildings: [],
            img: "https://firebasestorage.googleapis.com/v0/b/fortrinn-47822.appspot.com/o/housingSelector%2Fhousing-selector-test.png?alt=media&token=9270ab16-da89-4455-9778-63ddf090dd4e",
        }

        await createProject(newProject)
        enqueueSnackbar("Project created", {variant: 'success'});
        
        props.BackdropFn(false)
        props.refresh();
       
        handleClose();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Create new project"}
                </DialogTitle>
                <DialogContent>
                    <Stack direction={'column'} spacing={2} style={{width: '400px', marginTop: '20px'}}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            value={projectName}
                            onChange={e => setProjectName(e.target.value)}
                            label="Project Name"
                            name="name"
                            autoComplete="name"
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateClick}
                            variant={"contained"}
                            disabled={projectName.length === 0}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Button variant={"outlined"}
                    onClick={handleClickOpen}
                    startIcon={<AddBoxIcon />}
            >
                Create New Project
            </Button>
        </div>
    );
}