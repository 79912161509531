import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {getUnit} from "../../interfaces/housingUnit";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {ImageList, ImageListItem, Stack} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import Lightbox from "yet-another-react-lightbox";
import {Fullscreen, Download, Slideshow, Share, Zoom, Thumbnails, Inline} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {ContactForm} from "../../components/user/ContactForm";

function TableTitle ({title}) {
    return (
        <TableCell>
            <Typography variant={'h6'}>
                {title}
            </Typography>
        </TableCell>
    )
}

function TableContent ({content}) {
    return (
        <TableCell>
            <Typography variant={'body2'}>
                {content}
            </Typography>
        </TableCell>
    )
}

export default function HousingUnit(props) {
    const navigate = useNavigate();
    const { projectId, unitId } = useParams();

    const [unit, setUnit] = useState(undefined);
    const [imageIndex, setImageIndex] = useState(0);
    const [blueprintIndex, setBlueprintIndex] = useState(0);
    const [slideImages, setSlideImages] = useState([]);
    const [blueprintImages, setBlueprintImages] = useState([]);
    const [imagesOpen, setImagesOpen] = useState(false);
    const [blueprintsOpen, setBlueprintsOpen] = useState(false);
    
    const handleImagesOpen = (index) => {
        setImageIndex(index);
        setImagesOpen(true);
    }
    const handleBlueprintsOpen = (index) => {
        setBlueprintIndex(index);
        setBlueprintsOpen(true);
    }

    useEffect(() => {
        init();
    }, [projectId, unitId]);

    async function init () {
        if (projectId && unitId) {
            const updatedUnit = await getUnit(projectId, unitId);
            setUnit(updatedUnit);

            const updatedSlideImages = [];
            if (updatedUnit && updatedUnit.images && updatedUnit.images.length > 0) {
                updatedUnit.images.forEach(image => {
                    updatedSlideImages.push({
                        src: image.url,
                    })
                })
            }
            setSlideImages(updatedSlideImages)

            const updatedBlueprintImages = [];
            if (updatedUnit && updatedUnit.blueprints && updatedUnit.blueprints.length > 0) {
                updatedUnit.blueprints.forEach(blueprint => {
                    updatedBlueprintImages.push({
                        src: blueprint.url,
                    })
                })
            }
            setBlueprintImages(updatedBlueprintImages)
        }
    }

    if (!unit) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }

    function getFileExtensionIcon (name) {
        const ext = /(?:\.([^.]+))?$/.exec(name)[1];
        
        switch (ext)
        {
            case "pdf":
                return <PictureAsPdfIcon />;
            default:
                return <></>;
        }
    }
    
    function getFileName (name) {
        return name.replace(/\.[^/.]+$/, "");
    }

    return (
        <div>
            <Lightbox
                index={blueprintIndex}
                open={blueprintsOpen}
                close={() => setBlueprintsOpen(false)}
                slides={blueprintImages}
                carousel={{ preload: 3 }}
                render={{
                    buttonPrev: blueprintImages.length <= 1 ? () => null : undefined,
                    buttonNext: blueprintImages.length <= 1 ? () => null : undefined,
                }}
                plugins={blueprintImages.length > 1 ? [Fullscreen, Download, Slideshow, Share, Zoom, Thumbnails] : [Fullscreen, Download, Share, Zoom]}
                thumbnails={{
                    position: 'bottom',
                    width: 80,
                    height: 120,
                    border: 0,
                    borderRadius: 4,
                    padding: 4,
                    gap: 16,
                    showToggle: false,
                }}
            />
            <Lightbox
                index={imageIndex}
                open={imagesOpen}
                close={() => setImagesOpen(false)}
                slides={slideImages}
                carousel={{ preload: 3 }}
                plugins={[Fullscreen, Download, Slideshow, Share, Zoom, Thumbnails]}
                thumbnails={{
                    position: 'bottom',
                    width: 80,
                    height: 120,
                    border: 0,
                    borderRadius: 4,
                    padding: 4,
                    gap: 16,
                    showToggle: false,
                }}
            />
            <Grid container spacing={3} style={{padding: '100px'}}>
                <Grid item xs={6}>
                    <Typography variant={'h2'}>
                        {unit.name}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={'h6'}>
                        {unit.description}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={7}>
                    <Lightbox
                        slides={blueprintImages}
                        index={blueprintIndex}
                        on={{ 
                            click: ({ index: currentIndex }) => handleBlueprintsOpen(currentIndex),
                        }}
                        styles={{
                            container: {
                                backgroundColor: "rgba(0, 0, 0, 0)",
                                cursor: 'pointer',
                            },
                            thumbnailsContainer: {
                                backgroundColor: "rgba(0, 0, 0, 0)",
                            },
                            thumbnail: {
                                background: "rgba(0, 0, 0, 0)",
                            }
                        }}
                        carousel={{ 
                            finite: true,
                        }}
                        thumbnails={{
                            vignette: false,
                        }}
                        render={{
                            buttonPrev: blueprintImages.length <= 1 ? () => null : undefined,
                            buttonNext: blueprintImages.length <= 1 ? () => null : undefined,
                        }}
                        inline={{
                            style: {
                                width: "100%",
                                maxWidth: "900px",
                                aspectRatio: "3 / 2",
                                margin: "0 auto",
                            },
                        }}
                        plugins={blueprintImages.length > 1 ? [Inline, Thumbnails] : [Inline]}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableTitle title={"Status"} />
                                <TableContent content={unit.soldDate ? "Sold" : "Available"} />
                            </TableRow>
                            <TableRow>
                                <TableTitle title={"Name"} />
                                <TableContent content={unit.name} />
                            </TableRow>
                            <TableRow>
                                <TableTitle title={"Floor"} />
                                <TableContent content={unit.floor} />
                            </TableRow>
                            <TableRow>
                                <TableTitle title={"BRA-i"} />
                                <TableContent content={`${unit.usableSize}m²`} />
                            </TableRow>
                            <TableRow>
                                <TableTitle title={"Rooms"} />
                                <TableContent content={`${unit.numRooms} rom`} />
                            </TableRow>
                            <TableRow>
                                <TableTitle title={"Price"} />
                                <TableContent content={new Intl.NumberFormat('no-NB', {style: 'currency', currency: 'NOK', maximumFractionDigits: 0,}).format(unit.askingPrice)} />
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'column'} alignItems={'center'} spacing={2} padding={2}>
                        <Typography variant={'h5'}>
                            Dokumenter
                        </Typography>
                        <Stack direction={'row'} spacing={2}>
                            {
                                unit.documents && unit.documents.map((document, index) => {
                                    const fileName = getFileName(document.name);
                                    const icon = getFileExtensionIcon(document.name);
                                    
                                    return (
                                        <Button key={index} variant={'contained'} href={document.url} target={'_blank'} startIcon={icon}>
                                            {fileName}
                                        </Button>
                                    )
                                })
                            }
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'column'} alignItems={'center'} spacing={2} padding={2}>
                        <Typography variant={'h5'}>
                            Bilder
                        </Typography>
                        <ImageList variant="masonry" cols={3} gap={8}>
                            {
                                unit.images && unit.images.map((image, i) => (
                                    <ImageListItem key={i}
                                                   onClick={e => handleImagesOpen(i)}
                                                   sx={{cursor: 'pointer'}}
                                    >
                                        <img
                                            srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${image.url}?w=248&fit=crop&auto=format`}
                                            alt={image.name}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))
                            }
                        </ImageList>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <ContactForm />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={'column'} alignItems={'center'} spacing={2} padding={2}>
                        <Typography variant={'h5'}>
                            Kontakt
                        </Typography>
                        <Stack direction={'row'}>
                            
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    );
}