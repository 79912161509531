import React from 'react';
import Button from '@mui/material/Button';
import { getAuth, sendEmailVerification, signOut } from "firebase/auth";
import Grid from "@mui/material/Grid";
import {Card, CardHeader, Stack} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import {useSnackbar} from "notistack";

export default function VerifyAccount(props) {
    const auth = getAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function handleResendVerificationClicked(){
        sendEmailVerification(auth.currentUser)
            .then(r => {
                enqueueSnackbar('Verification email resent', {variant: 'success'});
            });
    }

    function handleSignOutClicked(){
        signOut();
    }

    return (
        <div style={{height: '100vh'}}>
            <Grid container justifyContent={'center'} alignItems={'center'} sx={{height: '100%'}} >
                <Grid xs={8} justifyContent="center" alignItems="center">
                    <Card>
                        <CardHeader title={'Please verify your email'} />
                        <CardContent>
                            <Stack direction={'column'} spacing={1}>
                                <Typography variant={'body1'}>
                                    Link has been sent to {auth.currentUser.email}. Please verify your email and refresh the page.
                                </Typography>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleResendVerificationClicked} color="primary">
                                Resend verification mail
                            </Button>
                            <Button onClick={handleSignOutClicked}>
                                Log out
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}