import React, {useEffect, useRef, useState} from "react";
import {ReactPhotoSphereViewer} from 'react-photo-sphere-viewer';
import {MarkersPlugin} from "@photo-sphere-viewer/markers-plugin";
import "@photo-sphere-viewer/markers-plugin/index.css"
import {ReactImageTurntable} from "react-image-turntable";
import {collection, getDocs, query, where} from 'firebase/firestore';
import {useNavigate, useSearchParams} from "react-router-dom";
import {db} from "../../../firebase";
import {createRoot} from "react-dom/client";
import PanoMarker from "./PanoMarker";

const plugins = [
    [
        MarkersPlugin,
        {
            markers: [
            ]
        }
    ]
];

export function PanoViewer (props) {
    const {id, target} = props;
    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [startImage, setStartImage] = useState("");
    const [selected, setSelected] = useState(null);
    const [viewStyle, setViewStyle] = useState ("panorama"); //Select between "turntable" or "panorama"

    const [images, setImages] = useState([]); //used for Turntable
    const [rotationDisabled, setRotationDisabled] = useState(true);
    const [buttons, setButtons] = useState([]); //buttons for Turntable

    const photoSphereRef = useRef();
    const markerPluginRef = useRef();

    useEffect(() => {
        if (id && target) {
            init();
        }
    }, []);

    useEffect(() => {
        if (data) {
            if (target && data[target]) {
                setStartImage(data[target].image);
                setSelected(data[target])
            } else {
                setStartImage(Object.entries(data)[0].image);
                setSelected(Object.entries(data)[0])
            }
        }
    }, [data]);
    
    useEffect(() => {
        if (data && target) {
            setSelected(data[target])
        }
    }, [target]);

    useEffect(() => {
        if (selected) {
            if (viewStyle == "panorama") {
                //photoSphereRef.current.setPanorama(selected.image, {speed: '2000rpm',position: { yaw: 0, pitch: 0 },}) //Setting the start position manually
                photoSphereRef.current.setPanorama(selected.image, {speed: '2000rpm', zoomOut:10})
                makeMarkers();
            }
        }
    }, [selected]);

    async function init() {
        if (viewStyle == "panorama") {
            await getProjectInfoCollection();
        } else if (viewStyle == "turntable") {
            //Get Lightstream/Turntable data from database. This data will be put in pano database later
            await getProjectInfo();
            const lightStreamData = await getProjectInfoLightStream();

            let tempButtons =[];
            if (lightStreamData) {
                for (const [key, value] of Object.entries(lightStreamData.points)) {
                    tempButtons.push({
                        name: value.name,
                        points: JSON.parse(value.points).reverse(),
                    })
                }
            }

            setButtons(tempButtons)

            let links = getImageArrayHorizontal();
            setImages(links.reverse());
        }
    }

    async function getProjectInfoCollection(){
        const projectId = id;
        const q = query(collection(db, "stream", projectId, 'pano'));
        const querySnapshot = await getDocs(q);
        let tempData = {};
        querySnapshot.forEach((doc) => {
            let docData = doc.data();
            docData.image = `https://fortrinn.s3.eu-north-1.amazonaws.com/projects/3iUfCJSzDaWjUS5XFBhY/pano/${docData.id}.jpg`;
            let newEntry = {[docData.id] : docData};
            tempData = {...tempData, ...newEntry};
        });
        
        //temp until we make a better way to setup and pass the target
        const fixedTarget = target ? target : 'nordbohusbyen'
        
        let item;
        if (tempData.hasOwnProperty(fixedTarget)) {
            item = tempData[fixedTarget];
            tempData.selected = item;
            tempData.selected.image = `https://fortrinn.s3.eu-north-1.amazonaws.com/projects/3iUfCJSzDaWjUS5XFBhY/pano/${fixedTarget}.jpg`;
        }

        setData(tempData);
    }

    //Get info on specific ID and target in panodatabase
    async function getProjectInfo(){
        let projectId = id;
        let tempData = {};

        if (projectId && target) {
            const q = query(collection(db, "stream", projectId, 'pano'), where("id", "==", target));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // console.log(doc.data())
                let docData = doc.data();
                docData.image = "https://fortrinn.s3.eu-north-1.amazonaws.com/projects/3iUfCJSzDaWjUS5XFBhY/pano/aurora.jpg";
                let newEntry = {[docData.id] : docData};
                tempData = {...tempData, ...newEntry};
            });
        }

        setData(tempData);
    }

    function makeMarkers () {
        if (markerPluginRef.current) {
            markerPluginRef.current.setMarkers();

            for (const [key, value] of Object.entries(selected.markers)) {
                let markersDivParent = document.createElement("div");
                const root = createRoot(markersDivParent);
                root.render(PanoMarker(value.id));

                markerPluginRef.current.addMarker({
                    id: value.id,
                    position: { yaw: value.location.pan + 'deg', pitch: -value.location.tilt + 'deg' },
                    rotation: { yaw: '0deg', pitch: '0deg', roll: '0deg' },
                    elementLayer: markersDivParent,
                    zIndex: 10,
                });
            }
        }
    }

    async function getProjectInfoLightStream() {
        let projectId = id
        let projectData
        if (projectId && target) {
            const q = query(collection(db, "stream", projectId, 'lightstream'), where("id", "==", target));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                projectData = doc.data();
            });
        }

        return projectData;
    }

    function handleKeyDown (event) {
        if (rotationDisabled) return;

        if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
            setRotationDisabled(true);
        }
    };


    function imageChanged (event) {
        if (buttons.length > 0) {
            buttons.forEach(button => {
                // turn into react refs
                const turnTableRef = document.getElementById("turntable");
                const buttonRef = document.getElementById(button.name);

                let buttonX = turnTableRef.offsetWidth * button.points[event][0]
                let buttonY = turnTableRef.offsetHeight * button.points[event][1]
                buttonRef.style.position = 'absolute';
                buttonRef.style.left = (buttonX.toString() + 'px')
                buttonRef.style.top = (buttonY.toString() + 'px')
            });
        }
    }

    function getImageArrayHorizontal() {
        const projectFolderUrl = `https://fortrinn.s3.eu-north-1.amazonaws.com/projects/${id}/lightstream/${target}/`;
        let imgMaxHorizontalSteps = 48
        let tempImgArray= []
        for (let index = 1; index <= imgMaxHorizontalSteps; index++) {
            let imgUrl = `${projectFolderUrl}${target}_H${index}.jpg`;
            tempImgArray.push(imgUrl);
        }
        return tempImgArray;
    }

    function floatingButton (event, newTarget) {
        event.preventDefault();
        
        let url= `?target=${newTarget}`;
        navigate(url);
    }

    function handleClick (instance) {
        // console.log(`yaw ${instance.state.yaw}`, ` pitch: ${instance.state.pitch}`);
    }

    function handlePosChange (lat, lon, instance) {
        // console.log(`yaw ${lat} pitch ${lon}`);
        // console.log(instance.state.direction);
        // console.log(`yaw ${instance.state.yaw}`, ` pitch: ${instance.state.pitch}`);
    }

    function handleReady (instance) {
        markerPluginRef.current = instance.getPlugin(MarkersPlugin);
        if (!markerPluginRef.current)
            return;

        makeMarkers()

        markerPluginRef.current.addEventListener("select-marker", (plug) => {
            let newTarget = plug.marker.config.id

            let url= `?target=${newTarget}`
            navigate(url);
        });

        markerPluginRef.current.addEventListener("enter-marker", (plug) => {
            // console.log(plug.marker.config.id)
        });
    }

    if (data == null || selected == null) {
        return (<>
        </>)
    }

    if (viewStyle == "turntable") {
        return (
            <div>
                <ReactImageTurntable id='turntable'
                                     style={{height:'100%'}}
                                     images={images}
                                     autoRotate={{ disabled:true}}
                                     onPointerDown={() => setRotationDisabled(true)}
                                     onPointerUp={() => setRotationDisabled(false)}
                                     onKeyDown={handleKeyDown}
                                     onKeyUp={() => setRotationDisabled(false)}
                                     onIndexChange={imageChanged}
                >
                </ReactImageTurntable>
                {
                    buttons.map((button, index) => {
                        return PanoMarker(button.name, floatingButton);
                    })
                }
            </div>
        )
    }

    return (
        <div>
            <ReactPhotoSphereViewer
                src={startImage}
                ref={photoSphereRef}
                height={'100vh'}
                moveSpeed={1.8}
                defaultZoomLvl={20}
                defaultYaw={5.25}
                defaultPitch={-0.39}
                width={"100%"}
                caption={"test"}
                hideNavbarButton={true}
                keyboard={'always'}
                moveInertia={false}
                onClick={handleClick}
                onPositionChange={handlePosChange}
                onReady={handleReady}
                plugins={plugins}
            >
            </ReactPhotoSphereViewer>
        </div>
    );
}

export default PanoViewer;