import {Icon, Stack, Typography} from "@mui/material";
import "./InfoComponent.css"
import Paper from "@mui/material/Paper";

export default function InfoComponent (props) {
    
    return (
        <Paper>
            <Stack direction={'row'} className={'container'} spacing={2}>
                <div className={'icon'}>
                    {props.icon}
                </div>
                <Stack direction={'column'} spacing={1} justifyContent={'space-around'} >
                    <Typography variant={'caption'} >
                        {props.title}
                    </Typography>
                    <Typography variant={'body1'} className={'text'} >
                        {props.text}
                    </Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}