import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import {Divider, Stack} from "@mui/material";
import {Card, CardHeader} from "@mui/material/";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {useSnackbar} from "notistack";
import {validateEmail} from "./validateEmail";
import {
    auth,
    linkWithCredential,
    sendPasswordReset,
    updateEmail,
    unlink, 
    linkWithGoogle, 
    linkWithMicrosoft,
} from "./firebase";
import {ReactComponent as GoogleIcon} from './img/google-icon.svg';
import {ReactComponent as MsIcon} from './img/ms-icon.svg';

const MyAccount = (props) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        if (props.userData) {
            setEmail(props.userData.email)
        }
    }, [props.userData])
    
    const handleResetPasswordClicked = (e) => {
        sendPasswordReset(props.userData.email)
            .then(res => {
                enqueueSnackbar('Successfully sent reset password instructions', {variant: 'success'});
            })
            .catch(err => {
                enqueueSnackbar('Something went wrong while resetting password', {variant: 'error'});
            });
    }

    const handleSaveAccountClicked = (e) => {
        if(!validateEmail(email)) {
            setEmailError('Email is invalid')
            enqueueSnackbar('Email is invalid', {variant: 'error'});
            return;
        }
        
        updateEmail(auth.currentUser, email)
            .then(res => {
                enqueueSnackbar('Email successfully updated', {variant: 'success'});
            })
            .catch(error => {
                enqueueSnackbar('Something went wrong while updating email', {variant: 'error'});
            });
    };

    const handleGoogleLogin = (e) => {
        e.preventDefault();
        linkWithGoogle()
            .then(res => {
                      
            })
            .catch(error => {
                
            });
    }

    const handleMicrosoftLogin = (e) => {
        e.preventDefault();
        linkWithMicrosoft()
            .then(res => {
                
            })
            .catch(error => {

            });
    }
    
    return (
        <div style={{height: '100vh'}}>
            <Grid container justifyContent={'center'} alignItems={'center'} sx={{height: '100%'}} >
                <Grid item xs={12} md={6} lg={4}>
                    <Card>
                        <CardHeader title={'Your Account'} />
                        <CardContent>
                            <Stack direction={'column'} spacing={1} alignItems={'center'}>
                                <Stack direction={'row'} spacing={1} sx={{width: '100%'}}>
                                    <TextField
                                        label={'Email'}
                                        type={'email'}
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        fullWidth
                                        error={emailError.length > 0}
                                        helperText={emailError.length > 0 ? emailError : ''}
                                    />

{/*                                     
                                    <Button onClick={handleSaveAccountClicked} disabled={props.userData.email === email}>
                                        Save
                                    </Button> */}


                                </Stack>
                                <Divider orientation="vertical" />
                                {/*<Button*/}
                                {/*    type="button"*/}
                                {/*    fullWidth*/}
                                {/*    variant="contained"*/}
                                {/*    color="primary"*/}
                                {/*    onClick={handleMicrosoftLogin}*/}
                                {/*    startIcon={<MsIcon style={{height: '24px', width: '24px'}} />}*/}
                                {/*>*/}
                                {/*    Link account wiht Microsoft*/}
                                {/*</Button>*/}
                                {/*<Button*/}
                                {/*    type="button"*/}
                                {/*    fullWidth*/}
                                {/*    variant="contained"*/}
                                {/*    color="primary"*/}
                                {/*    onClick={handleGoogleLogin}*/}
                                {/*    startIcon={<GoogleIcon style={{height: '24px', width: '24px'}} />}*/}
                                {/*>*/}
                                {/*    Link account with Google*/}
                                {/*</Button>*/}
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button onClick={handleResetPasswordClicked}>
                                Reset password
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default MyAccount;