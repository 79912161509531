import Grid from "@mui/material/Grid";
import {Checkbox, FormControlLabel, Snackbar, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useState} from "react";

export const ContactForm = (props) => {
    const { onSendCallback, formMetaData } = props;

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [consent, setConsent] = useState(false);

    const [snackbarInfo, setSnackbarInfo] = useState({
        show: false,
        type: 'success',
        message: '',
    });

    async function sendForm (event) {
        event.preventDefault();

        if (onSendCallback)
            onSendCallback({
                name,
                phone,
                email,
                formMetaData,
            });

        setSnackbarInfo({
            show: true,
            type: 'success',
            message: 'We received your message and will get back to you soon',
        });
    }

    function onCloseSnackbar () {
        setSnackbarInfo({
            show: false,
            type: 'success',
            message: '',
        });

        setName('');
        setPhone('');
        setEmail('');
        setConsent(false);
    }

    function isFormInvalid () {
        if (name.length <= 0)
            return true;

        if (phone.length <= 0)
            return true;

        if (email.length <= 0)
            return true;

        if (!consent)
            return true;

        return false;
    }

    return (
        <div>
            <Snackbar
                open={snackbarInfo.show}
                autoHideDuration={5000}
                onClose={onCloseSnackbar}
                message={snackbarInfo.message}
            />
            <Grid container>
                <Grid item xs={12}>
                    <form onSubmit={sendForm} id="testForm">
                        <Stack direction={'column'} alignItems={'center'} spacing={3}>
                            <Typography variant={'h5'}>
                                Ta kontakt for å vise interesse
                            </Typography>
                            <TextField
                                fullWidth
                                label={'Name'}
                                type={'text'}
                                placeholder={'Fill in your name'}
                                variant={'standard'}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label={'Phone'}
                                type={'tel'}
                                placeholder={'Fill in your phone number'}
                                variant={'standard'}
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                            />
                            <TextField
                                fullWidth
                                label={'Email'}
                                type={'email'}
                                placeholder={'Fill in your email'}
                                variant={'standard'}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                            <FormControlLabel control={<Checkbox checked={consent}
                                                                 onChange={e => setConsent(e.target.checked)} />}
                                              label="Jeg ønsker å bli kontaktet i samtykke med denne personvernerklæringen"
                            />
                            <Button
                                type={'submit'}
                                variant={'contained'}
                                fullWidth
                                disabled={isFormInvalid()}
                            >
                                Show interest
                            </Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </div>
    )
}