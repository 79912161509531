import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
    Avatar,
    Button,
    IconButton,
    ListItemButton,
    Menu,
    MenuItem,
    Stack,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth, db, logout} from "./firebase";
import {useEffect, useState, useMemo, forwardRef, cloneElement} from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MenuIcon from "@mui/icons-material/Menu";
import GetAppIcon from "@mui/icons-material/GetApp";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Badge from "@mui/material/Badge";
import Gravatar from "react-gravatar";
// import Projects from "./Projects";
import logo from "./img/logo.png";
import {doc, onSnapshot} from "firebase/firestore";
import MuiAlert from "@mui/material/Alert";
import {useSnackbar} from "notistack";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ViewListIcon from '@mui/icons-material/ViewList';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';

const drawerWidth = 250;

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AuthenticatedView({ children, redirectTo, handleDarkModeClickCallback }) {
    let navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [user, loading, error] = useAuthState(auth);
    const [drawerOpenState, setDrawerOpenState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (!user || loading)
            return

        const uid = user.uid;

        const userSubscription = onSnapshot(doc(db, "users", uid), (doc) => {
            const data = doc.data();
            setUserData({
                ...data,
                uid: uid,
            })
        });

        return (() => {
            userSubscription();
        })
    }, [user]);

    const handleAccountMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAccountMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMyAccountClicked = (e) => {
        handleAccountMenuClose();
        navigate('/account')
    };

    const handleSignOutClicked = (e) => {
        handleAccountMenuClose();
        enqueueSnackbar(`Successfully logged out`, {variant: 'info'});
        logout();
    };

    const handleDrawerOpen = (e) => {
        setDrawerOpenState(true);
    };
    const handleDrawerClose = (e) => {
        setDrawerOpenState(false);
    };

    const handleDarkModeClick = (e) => {
        if(handleDarkModeClickCallback) {
            const updatedDarkMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
            enqueueSnackbar(`Changed to ${updatedDarkMode} mode`, {variant: 'info'});
            handleDarkModeClickCallback(e);
        }
    }

    if(!userData) {
        return <div>
        </div>
    }

    return (
        <div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAccountMenuClose}
            >
                {
                    user.emailVerified &&
                    <MenuItem onClick={handleMyAccountClicked}>
                        <ListItemIcon>
                            <AccountBoxIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>My account</ListItemText>
                    </MenuItem>
                }
                <MenuItem onClick={handleSignOutClicked}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </MenuItem>
            </Menu>
            <Backdrop
                sx={{ color: '#fff', zIndex: 100000 }}
                open={backdropOpen}
                //onClick={handleBackdropClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/*<ModalLinkAccount linkAccounts={props.linkAccounts} LinkAccountsFn={props.LinkAccountsFn}/>*/}

            <AppBar position="sticky" elevation={0} >
                <Toolbar>
                    <Stack direction={'row'} spacing={1} alignItems={"center"} >
                        {
                            user.emailVerified && userData.role == 'admin' &&
                            <IconButton edge="start" onClick={handleDrawerOpen} aria-label="menu">
                                <MenuIcon />
                            </IconButton>
                        }
                        <IconButton component={Link} to={"/"}>
                            <img src={logo} width={32} height={32} />
                        </IconButton>
                    </Stack>
                    <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} alignItems={"center"} sx={{ flexGrow: 1 }} >
                        <IconButton onClick={handleDarkModeClick}>
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleAccountMenuOpen}>
                            <Badge badgeContent={0} color="secondary">
                                <Avatar>
                                    {
                                        userData?.email && <Gravatar email={userData?.email} />
                                    }
                                </Avatar>
                            </Badge>
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left"
                    open={drawerOpenState}
                    onClose={handleDrawerClose}
            >
                <Box
                    sx={{ width: drawerWidth }}
                    role="presentation"
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                >
                    <List>
                        <ListItem onClick={e => navigate('/housing-selector')} >
                            <ListItemButton selected={location.pathname === "/housing-selector"}>
                                <ListItemIcon>{<ApartmentIcon />}</ListItemIcon>
                                <ListItemText primary={'Boligvelger'} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {cloneElement(children, {userData: userData})}
            </Box>
        </div>
    );
}

export default AuthenticatedView;
