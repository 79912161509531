import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function HouseNavigationCards(props) {
  return (
    <Card onClick={() => props.clickevent(props.id)}  sx={{ cursor: 'pointer',borderRadius:'50px',border:'1px', borderBlockStyle:'outset', borderColor:'white', maxWidth: props.smallscreen==true?130:200, minWidth: props.smallscreen==true?100:100, maxHeight:props.smallscreen==true?110:200, backgroundColor: 'rgb(240, 240, 240, 1)', ':hover':{backgroundColor: 'rgb(240, 240, 240, 0.42)'}}}>
{/*    
      <CardMedia
        component="img"
        alt=""
        height="80%"
        src={`${props.image}?cachebuster=${new Date().getTime()}`}
        //image={props.image}
        //style={{minWidth:"200"}}
        
      />
        */}
      <CardContent style={{padding: '0px'}}>
      <Button size="small" style={{padding: '5px 0px'}} onClick={() => props.clickevent(props.id)}>
        <Typography gutterBottom variant="h5" component="div" sx={{textTransform:"none",fontSize: props.smallscreen==true?'0.85rem':"1.3rem" }}>
          {props.title} 
          {/* {props.smallscreen.toString()} */}
        </Typography>
        </Button>
        <Typography variant="body2" color="text.secondary">
          {/* {props.infotext} */}
        </Typography>
      </CardContent>
{/*       
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}