import logo from './logo.svg';
import './App.css';
import Pano from './Pano';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
// import Lano from './Lano';
// import PanoSimpel from './PanoSimpel';
// import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer';
import React, {useMemo, useState} from 'react';
import VerifyAccount from "./VerifyAccount";
import AuthenticatedView from "./AuthenticatedView";
import HousingAdminDashboard from "./HousingSelector/pages/admin/HousingAdminDashboard";
import HousingSelector from "./HousingSelector/pages/admin/HousingSelector";
import HousingProjectEdit from "./HousingSelector/pages/admin/HousingProjectEdit";
import HousingUnitEdit from "./HousingSelector/pages/admin/HousingUnitEdit";
import HousingProject from "./HousingSelector/pages/user/HousingProject";
import HousingProjectLanding from "./HousingSelector/pages/user/HousingProjectLanding";
import HousingUnit from "./HousingSelector/pages/user/HousingUnit";
import {createTheme, useMediaQuery} from "@mui/material";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import MyAccount from "./MyAccount";

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        background: {
            default: mode === 'light' ? 'rgb(248, 249, 250)' : 'rgb(14, 19, 32)',
        },
        // primary: {
        //     // light: will be calculated from palette.primary.main,
        //     main: '#272727',
        //     // dark: will be calculated from palette.secondary.main,
        //     // contrastText: will be calculated to contrast with palette.primary.main
        // },
        // secondary: {
        //     // light: '#0066ff',
        //     main: '#FBFEF9',
        //     // dark: will be calculated from palette.secondary.main,
        //     // contrastText: '#ffcc00',
        // },
        // rgb(40, 44, 52)
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backdropFilter: 'blur(6px)',
                    backgroundColor: mode === 'light' ? 'rgba(248, 249, 250, 0.8)' : 'rgba(14, 19, 32, 0.8)'
                }
            },
        },
        MuiCard : {
            styleOverrides: {
                root: {
                    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
                }
            },
        }
    },
});

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkModeOverride, setDarkModeOverride] = useState(localStorage.getItem("darkState"));
    const [backdropOpen, setBackdropOpen] = useState(false);

    const theme = useMemo(
        () => {
            let darkMode = prefersDarkMode ? 'dark' : 'light';
            if(darkModeOverride != null) {
                darkMode = darkModeOverride;
            }

            return createTheme({
                typography: {
                    useNextVariants: true,
                    fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        '"Helvetica Neue"',
                        'Arial',
                        'sans-serif',
                        '"Apple Color Emoji"',
                        '"Segoe UI Emoji"',
                        '"Segoe UI Symbol"',
                    ].join(','),
                },
                ...getDesignTokens(darkMode),
            })
        },
        [prefersDarkMode, darkModeOverride],
    );

    const handleDarkModeClick = (event) => {
        const updatedDarkMode = darkModeOverride === 'dark' ? 'light' : 'dark';
        setDarkModeOverride(updatedDarkMode)
        localStorage.setItem("darkState", updatedDarkMode);
    };

    const BackdropFn = (status) => {
        //console.log('set backdrop to ' + status)
        setBackdropOpen(status);
    }
    
    return (
        <div className="App">
            {/* <Lano/> */}
            <Router>
                <Routes>
                    {/* <Pano/> */}
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route
                        path="/housing-selector"
                        element={
                            <RequireAuth redirectTo="/signin">
                                <AuthenticatedView handleDarkModeClickCallback={handleDarkModeClick} >
                                    <HousingAdminDashboard BackdropFn={BackdropFn}/>
                                </AuthenticatedView>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/housing-selector/test"
                        element={
                            <RequireAuth redirectTo="/signin">
                                <AuthenticatedView handleDarkModeClickCallback={handleDarkModeClick} >
                                    <HousingSelector BackdropFn={BackdropFn}/>
                                </AuthenticatedView>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/housing-selector/edit/:projectId"
                        element={
                            <RequireAuth redirectTo="/signin">
                                <AuthenticatedView handleDarkModeClickCallback={handleDarkModeClick} >
                                    <HousingProjectEdit BackdropFn={BackdropFn}/>
                                </AuthenticatedView>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/housing-selector/edit/:projectId/:unitId"
                        element={
                            <RequireAuth redirectTo="/signin">
                                <AuthenticatedView handleDarkModeClickCallback={handleDarkModeClick} >
                                    <HousingUnitEdit BackdropFn={BackdropFn}/>
                                </AuthenticatedView>
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/housing-selector/:projectId"
                        element={
                            <HousingProject />
                        }
                    />
                    <Route
                        path="/housing-selector/:projectId/landing"
                        element={
                            <HousingProjectLanding />
                        }
                    />
                    <Route
                        path="/housing-selector/:projectId/:unitId"
                        element={
                            <HousingUnit />
                        }
                    />
                    <Route
                        path="/account"
                        element={
                            <RequireAuth redirectTo="/signin">
                                <AuthenticatedView handleDarkModeClickCallback={handleDarkModeClick} >
                                    <MyAccount />
                                </AuthenticatedView>
                            </RequireAuth>
                        }
                    />
                    <Route path="/" element={<Pano />} />
                </Routes>
            </Router>

            {/* <PanoSimpel/> */}
        </div>
    );
}

function RequireAuth({ children, redirectTo }) {
    const [user, loading, error] = useAuthState(auth);
    if (loading || user) {
        if (user && !user.emailVerified) {
            return <VerifyAccount />
        }
        else {
            return children;
        }
    }
    else {
        return <Navigate to={redirectTo} />
    }
}

export default App;
