import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ContactForm} from "../../components/user/ContactForm";
import {getProject} from "../../interfaces/housingProject";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Stack} from "@mui/material";

const unitPreviewCardsStyle = {
    position: 'absolute',
    right: '400px',
    top: '0px',
    width: '350px',
    zIndex: 100,
}

const keyImageStyle = {
    position: 'relative',
    width: '100%',
    height: '400px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
}

const insideImageContentStyle = {
    padding: '20px',
    width: '450px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    marginLeft: '20px',
    borderRadius: '5px',
}

const contactFormBoxStyle = {
    margin: '50px',
}

export default function HousingProjectLanding (props) {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [project, setProject] = useState({});

    useEffect(() => {
        init();
    }, [projectId]);

    async function init () {
        if (projectId) {
            const updatedProject = await getProject(projectId);
            setProject(updatedProject);
        }
    }
    
    console.log(project)

    if (!project)
        return (
            <div>

            </div>
        )

    return (
        <div>
            <Box 
                sx={keyImageStyle}
                style={{backgroundImage: `url(${project.keyImage?.url})`}}
            >
                <Box sx={insideImageContentStyle}>
                    <Stack direction={'column'} spacing={4} justifyContent={'left'}>
                        <Typography variant={'h6'}>
                            {project.description}
                        </Typography>
                        <Button variant={"contained"}
                                onClick={e => navigate(`/housing-selector/3iUfCJSzDaWjUS5XFBhY?target=nordbohusbyen`)}
                        >
                            Til Boligvelger
                        </Button>
                    </Stack>
                </Box>
            </Box>
            <Box sx={contactFormBoxStyle}>
                <ContactForm />
            </Box>
        </div>
    )
}

