import {addDoc, collection, doc, getDoc, getDocs, setDoc, updateDoc, arrayUnion, deleteDoc} from "firebase/firestore";
import {db, storage} from "../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export async function createUnit (projectId, newUnit) {
    const collectionRef = collection(db, "housingSelector", projectId, "units");
    const newDoc = await addDoc(collectionRef, newUnit);
    return newDoc;
}

export async function getUnits (projectId) {
    const collectionRef = collection(db, "housingSelector", projectId, "units");

    return await getDocs(collectionRef)
        .then(querySnapshot => {
            const units = [];

            querySnapshot.forEach(snapshot => {
                const data = snapshot.data();
                const id = snapshot.id;

                units.push({
                    id: id,
                    ...data,
                    registeredDate: data.registeredDate?.toDate(),
                    inspectionDate: data.inspectionDate?.toDate(),
                    commissionedDate: data.commissionedDate?.toDate(),
                    marketedDate: data.marketedDate?.toDate(),
                    showingDate: data.showingDate?.toDate(),
                    soldDate: data.soldDate?.toDate()
                })
            })

            return units;
        })
        .catch(error => {
            console.log(error);
        })
}

export async function getUnit (projectId, unitId) {
    const docRef = doc(db, "housingSelector", projectId, "units", unitId);

    return await getDoc(docRef)
        .then(docSnapshot => {
            const data = docSnapshot.data();
            
            return {
                id: docSnapshot.id,
                ...data,
                registeredDate: data.registeredDate?.toDate(),
                inspectionDate: data.inspectionDate?.toDate(),
                commissionedDate: data.commissionedDate?.toDate(),
                marketedDate: data.marketedDate?.toDate(),
                showingDate: data.showingDate?.toDate(),
                soldDate: data.soldDate?.toDate()
            }
        })
        .catch(error => {
            console.log(error);
        })
}

export async function updateUnit (projectId, unitId, updatedUnit) {
    const docRef = doc(db, "housingSelector", projectId, "units", unitId);

    return await setDoc(docRef, updatedUnit, { merge: true });
}

export async function deleteUnit (projectId, unitId) {
    const docRef = doc(db, "housingSelector", projectId, "units", unitId);

    return await deleteDoc(docRef);
}

export async function addImage (projectId, unitId, file, type) {
    const storageRef = ref(storage, `housingSelector/${projectId}/${unitId}/${type}/${file.name}`);
    const newImages = [];

    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file)
        .then(async (snapshot) => {
            // console.log('Uploaded a blob or file!');
            await getDownloadURL(snapshot.ref)
                .then(url => {
                    newImages.push({
                        url: url,
                        type: type,
                    });
                    console.log(newImages)
                });
        });

    const docRef = doc(db, "housingSelector", projectId, "units", unitId);

    await updateDoc(docRef, {
        [type]: arrayUnion.apply(this, newImages)
    });
}

export async function removeImage (projectId, unitId, imageData) {

}