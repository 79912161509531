import Box from "@mui/material/Box";
import {Fade, Stack} from "@mui/material";
import {UnitCard} from "../../components/user/UnitCard";
import React, {useState} from "react";
import PanoViewer from "../../components/user/PanoViewer";
import UnitList from "../../components/user/UnitList";
import {useParams, useSearchParams} from "react-router-dom";
import {units} from "../../data/testUnits";

const unitPreviewCardsStyle = {
    position: 'absolute',
    right: '400px',
    top: '0px',
    width: '350px',
    zIndex: 100,
}

export default function HousingProject (props) {
    const { projectId } = useParams(); // example: '3iUfCJSzDaWjUS5XFBhY';
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [hoverUnit, setHoverUnit] = useState(null);

    const target = searchParams.get("target");

    function handleOnSelectedUnit (unit) {
        if (selectedUnit && unit.id == selectedUnit.id)
            unit = null;

        setSelectedUnit(unit);
    }

    const showHover = (!selectedUnit && hoverUnit) || (selectedUnit && hoverUnit && hoverUnit.id != selectedUnit.id) ? true : false;
    const showSelected = selectedUnit ? true : false;
    
    if (!projectId)
        return (
            <div>
                
            </div>
        )
    
    return (
        <div>
            <PanoViewer id={projectId} target={target} />
            <Box sx={unitPreviewCardsStyle}>
                <Stack direction={'column'} spacing={2} padding={1}>
                    {
                        showSelected &&
                        <Fade in={showSelected} timeout={{enter: 500, exit: 500}} easing={"ease-out"}>
                            <div style={{minHeight: '0px'}}>
                                <UnitCard unit={selectedUnit} handleCloseCallback={() => setSelectedUnit(null)} />
                            </div>
                        </Fade>
                    }
                    {
                        showHover &&
                        <Fade in={showHover} timeout={{enter: 500, exit: 500}} easing={"ease-out"}>
                            <div>
                                <UnitCard unit={hoverUnit} comparisonVersion />
                            </div>
                        </Fade>
                    }
                </Stack>
            </Box>
            <UnitList onSelectedCallback={handleOnSelectedUnit} onHoverCallback={unit => setHoverUnit(unit)} units={units} />
        </div>
    )
}

