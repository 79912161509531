export const units = [
  {
    id: 0,
    group: "Bygg A",
    name: "A108",
    price: 2750000,
    currency: "NOK",
    usableSize: 34,
    numRooms: 3,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 1,
    name: "A206",
    group: "Bygg A",
    price: 4550000,
    currency: "NOK",
    usableSize: 64,
    numRooms: 4,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 2,
    name: "A101",
    group: "Bygg A",
    price: 3250000,
    currency: "NOK",
    usableSize: 40,
    numRooms: 3,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 3,
    name: "B221",
    group: "Bygg A",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 4,
    group: "Bygg B",
    name: "A108",
    price: 2750000,
    currency: "NOK",
    usableSize: 34,
    numRooms: 3,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 5,
    name: "A206",
    group: "Bygg B",
    price: 4550000,
    currency: "NOK",
    usableSize: 64,
    numRooms: 4,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 6,
    name: "A101",
    group: "Bygg B",
    price: 3250000,
    currency: "NOK",
    usableSize: 40,
    numRooms: 3,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 7,
    name: "B221",
    group: "Bygg B",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 8,
    group: "Bygg B",
    name: "A108",
    price: 2750000,
    currency: "NOK",
    usableSize: 34,
    numRooms: 3,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 9,
    name: "A206",
    group: "Bygg B",
    price: 4550000,
    currency: "NOK",
    usableSize: 64,
    numRooms: 4,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 10,
    name: "A101",
    group: "Bygg B",
    price: 3250000,
    currency: "NOK",
    usableSize: 40,
    numRooms: 3,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 11,
    name: "B221",
    group: "Bygg C",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 12,
    group: "Bygg C",
    name: "A108",
    price: 2750000,
    currency: "NOK",
    usableSize: 34,
    numRooms: 3,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 13,
    name: "A206",
    group: "Bygg C",
    price: 4550000,
    currency: "NOK",
    usableSize: 64,
    numRooms: 4,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 14,
    name: "A101",
    group: "Bygg C",
    price: 3250000,
    currency: "NOK",
    usableSize: 40,
    numRooms: 3,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 15,
    name: "B221",
    group: "Bygg C",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 16,
    name: "B221",
    group: "Bygg C",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 17,
    group: "Bygg C",
    name: "A108",
    price: 2750000,
    currency: "NOK",
    usableSize: 34,
    numRooms: 3,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 18,
    name: "A206",
    group: "Bygg C",
    price: 4550000,
    currency: "NOK",
    usableSize: 64,
    numRooms: 4,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 19,
    name: "A101",
    group: "Bygg C",
    price: 3250000,
    currency: "NOK",
    usableSize: 40,
    numRooms: 3,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 20,
    name: "B221",
    group: "Bygg C",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 21,
    name: "B221",
    group: "Bygg C",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 22,
    group: "Bygg C",
    name: "A108",
    price: 2750000,
    currency: "NOK",
    usableSize: 34,
    numRooms: 3,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 23,
    name: "A206",
    group: "Bygg C",
    price: 4550000,
    currency: "NOK",
    usableSize: 64,
    numRooms: 4,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 24,
    name: "A101",
    group: "Bygg C",
    price: 3250000,
    currency: "NOK",
    usableSize: 40,
    numRooms: 3,
    sold: true,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
  {
    id: 25,
    name: "B221",
    group: "Bygg C",
    price: 5250000,
    currency: "NOK",
    usableSize: 70,
    numRooms: 5,
    sold: false,
    keyImage: 'https://res.cloudinary.com/plyo/image/upload/q_auto/c_limit,g_center,h_600,w_600/v1706695591/molobyen/dudaqanmpzlt8oozcffa.png',
  },
]