import {addDoc, arrayUnion, collection, doc, getDoc, getDocs, setDoc, updateDoc} from "firebase/firestore";
import {db, storage} from "../../firebase";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";

export async function getProjects () {
    const collectionRef = collection(db, "housingSelector");
    return await getDocs(collectionRef)
        .then((querySnapshot) => {
            const housingProjects = [];

            querySnapshot.forEach(snapshot => {
                const data = snapshot.data();
                const id = snapshot.id;

                housingProjects.push({
                    id: id,
                    ...data,
                })
            })

            return housingProjects;
        });
}

export async function getProject (projectId) {
    const docRef = doc(db, "housingSelector", projectId);
    return await getDoc(docRef)
        .then((doc) => {
            if (!doc.exists()) {
                return null;
            } else {
                const data = doc.data();
                return data;
            }
        });
}

export async function updateProject (projectId, projectChanges) {
    const docRef = doc(db, "housingSelector", projectId);
    return await setDoc(docRef, {
        ...projectChanges,
    }, {merge: true});
}

export async function createProject (newProject) {
    const collectionRef = collection(db, "housingSelector");
    return await addDoc(collectionRef, newProject);
}

export async function addImage (projectId, file, type, name) {
    const storageRef = ref(storage, `housingSelector/${projectId}/${type}/${name}`);
    let newImage;

    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file)
        .then(async (snapshot) => {
            // console.log('Uploaded a blob or file!');
            await getDownloadURL(snapshot.ref)
                .then(url => {
                    newImage = {
                        url: url,
                        type: type,
                    };
                });
        });

    const docRef = doc(db, "housingSelector", projectId);

    await updateDoc(docRef, {
        [type]: newImage
    });
}

export async function removeImage (projectId, imageData) {

}