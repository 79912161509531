import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ImageCards(props) {
  return (
    <Card onClick={() => props.clickevent(props.id)}  sx={{borderRadius: 0,borderRadius:'5px',border:'3px', borderBlockStyle:'outset', borderColor:'white', maxWidth: props.smallscreen==true?120:220, minWidth: props.smallscreen==true?100:200, maxHeight:props.smallscreen==true?100:145,backgroundColor: 'rgb(240, 240, 240, 1)', ':hover':{backgroundColor: 'rgb(240, 240, 240, 0.42)'}}}>
     
      <CardMedia
        component="img"
        alt=""
        height="80%"
        //src={`${props.image}?cachebuster=${new Date().getTime()}`}
        image={props.image}
        //style={{minWidth:"200"}}
        
      />
        
      <CardContent style={{padding: '0px'}}>
        <Typography gutterBottom variant="h5" component="div" sx={{fontSize: props.smallscreen==true?'0.85rem':"1.3rem" }}>
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {/* {props.infotext}  */}
        </Typography>
      </CardContent>
{/*       
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}